import axios from "axios";
import { useEffect } from "react";
import SidebarMenu from "../SidebarMenu";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import { useState } from 'react';
import fileIcon from "../../images/fileicon.png"

const PodView = () => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const SERVER_PATH = process.env.REACT_APP_SERVER_PATH;
    const role = sessionStorage.getItem('role');
    const user = sessionStorage.getItem('userid');   
    const url=window.location.href;
    const get_id = url.split("/").pop();

    useEffect(() => {
        getPodview();
    }, [])

    
    const [isload, setisload] = useState(true);
    const [data, setdata] = useState([]);
    const getPodview = async () => {
        const API_PATH = `${SERVER_URL}pod.php`;
        const passval = {
            type: "podview",
            manager: get_id
        };
        await axios
            .post(API_PATH, passval)
            .then(res => {
                console.log(res);
                setdata(res.data.list)
                setisload(false);  
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const columns = [
        {
            name: "Sl.NO",
            selector: row => row.count,
            sortable: true,
        },
        {
            name: "ID",
            selector: row => row.id,
            sortable: true,
        },
        {
            name: "POD FILE",
            cell: (row) => <>
                <a href={`${SERVER_PATH}/uploads/POD/${row.filename}`} target="_blank" style={{textAlign: "left"}} ><img src={fileIcon} style={{width: "15%"}} /></a>
            </>,
        },
        {
            name: "POD REFERENCE",
            selector: row => row.filename,
            sortable: true,
        },
        {
            name: "POD DATE",
            selector: row => row.updated_on,
            sortable: true,
        },
    ]

    const tableData = {
      columns,
      data
    };

    return(
        <div className="wrapper-holder">
            <SidebarMenu/>
            
            <div className="main-panel ps-container">                
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">   

                                    <div className="card-header card-header-primary">
                                        <h4 className="card-title ">POD's <span style={{ float: 'right' }}> <Link to="/upload-pod">+</Link></span></h4>
                                        <p className="card-category">Please click the file to view it.</p>
                                    </div>
                                    <div className="card-body">
                                        {
                                            isload?(
                                                <div style={{textAlign:"center"}}>
                                                    <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>
                                                </div>
                                            ):(
                                                <DataTableExtensions {...tableData}>
                                                    <DataTable 
                                                        columns={columns}
                                                        data={data}
                                                        noHeader
                                                        defaultSortField="id"
                                                        // sortIcon={<SortIcon />}
                                                        defaultSortAsc={true}
                                                        pagination
                                                        highlightOnHover
                                                        dense
                                                    />
                                                </DataTableExtensions>
                                            )
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PodView;