import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const PodModal = () => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const role = sessionStorage.getItem('role');
    const user = sessionStorage.getItem('userid');     
    const navigate=useNavigate(); 

    const defaultValues = {
        pod_branch: '33',
        podmanager: ''
    }

    const [poddata, setpoddata] = useState(defaultValues);
    const [err_msg, set_err_msg] = useState();
    const submit_report = async () => {
        if(poddata.podmanager == ''){
            set_err_msg("Please enter all the required field!");
            return false; // stop here...  
        }else{
            navigate(`/pod-list/${poddata.podmanager}`); 
        }
        console.log(poddata);
    }

    return(
        <div className="wrapper fadeInDown pod-holder">
            <div className="row md-holder">
                <div className="col-md-12 con-pod">
                    <h4 className="card-title">Review POD</h4> 
                </div>
                
                <div className="col-md-12 con-pod">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Branch</label>
                                <select id="pod_branch" name="pod_branch" onChange={(e)=>setpoddata({...poddata,pod_branch:e.target.value})}  className="form-control " data-live-search="true" >
                                    <option value={poddata.pod_branch}>Mubadala</option>
                                </select>  
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Manager</label>
                                <select id="podmanager" name="podmanager" onChange={(e)=>setpoddata({...poddata,podmanager:e.target.value})}  className="form-control " data-live-search="true" >
                                    <option value="">Select</option>
                                    <option value="1">Admin</option>
                                    <option value="24">Anthony</option>
                                    <option value="15">Hassan</option>
                                    <option value="3">Maynard</option>
                                    <option value="10">Naseer</option>
                                </select>  
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                
                <div className="col-md-12" style={{textAlign: 'right'}}>
                    <p style={{ color: "red" }}>{err_msg}</p>
                    <a className="btn btn-primary pull-right" onClick={submit_report} style={{color: '#fff'}}>Get Report</a>
                </div>
            </div>
        </div>
    )
}
export default PodModal;