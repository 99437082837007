import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import '../../Form.css'
import Checklogin from './Checklogin';
import { useNavigate } from 'react-router-dom';

export default function EditVehicleType() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const navigate=useNavigate()
  
  const [msg, setmsg] = useState()
  const url=window.location.href
  const vehicletypeid = url.split("/").pop();
  const [vehicletype, setvehicletype] = useState({
    veh_type:'',
    fuel_allowance:'',
    km_allowance:''
})
  const API_PATH = `${SERVER_URL}vehicle_type.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data:{user:user,vehicletypeid:vehicletypeid,type:'details'}
    }).then((res) => {
     console.log(res.data)
     setvehicletype(res.data)
    })
      .catch((err) => console.log(err))

  }, [])
  
  function submit_vehicletype(val){
    if(vehicletype.veh_type!='' && vehicletype.fuel_allowance !='' && vehicletype.km_allowance !=''){
        axios({
            method:'post',
            url:API_PATH,
            data:{'user':user,'id':val,vehicletype:vehicletype,type:'update'}
        })
        .then((res)=>{
            
            if(res.data.allowed==0){
                setmsg(res.data.message)
                navigate('/vehicle-type-list')
            }else{
                setmsg(res.data.message)
            }
        })
        .catch((err)=>console.log(err.message))
    }else{
        setmsg('please enter all the required fields')
    }
  }

  return (
    <div className='wrapper-holder'>
      {<SidebarMenu />}
    
      <div className="main-panel ps-container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-primary">
                    <h4 className="card-title">Add Vehicle Type</h4>
                    
                  </div>
                  <div className="card-body">
                    <form id="myform" encType="multipart/form-data">
                    <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Vehicle Type <span style={{ color: 'red' }}> *</span></label>
                            <input type="text" id="vehicle_type" name="vehicle_type" defaultValue={vehicletype.veh_type} className="form-control" onChange={(e)=>setvehicletype({...vehicletype,veh_type:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Fuel Allowance / Month<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" id="fuel_allowance" name="fuel_allowance" defaultValue={vehicletype.fuel_allowance} className="form-control" onChange={(e)=>setvehicletype({...vehicletype,fuel_allowance:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">KM Allowance / Month <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="km_allowance" name="km_allowance" defaultValue={vehicletype.km_allowance}  className="form-control" onChange={(e)=>setvehicletype({...vehicletype,km_allowance:e.target.value})}  />
                          </div>
                        </div>
                      </div>
                    
                      <a className="btn btn-primary pull-right" onClick={()=>submit_vehicletype(vehicletype.id)} style={{ color: '#fff !important' }} id="addusr">Update Profile</a>
                      <p>{msg}</p>
                        </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
