import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import loading from '../../images/spin.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faClose, faCalendarDays, faAddressCard, faPenToSquare, faBatteryEmpty } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import '../../Form.css'
import Checklogin from './Checklogin';
import { Link, useNavigate } from 'react-router-dom';
const car = <FontAwesomeIcon icon={faCar} style={{ color: 'blue' }} />
const deleteicon = <FontAwesomeIcon icon={faClose} style={{ color: 'red' }} />
const calender = <FontAwesomeIcon icon={faCalendarDays} style={{ color: 'green' }} />
const addresscard = <FontAwesomeIcon icon={faAddressCard} style={{ color: 'purple' }} />
const editicon = <FontAwesomeIcon icon={faPenToSquare} style={{ color: 'blue' }} />
export default function LeaveApplications() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const SERVER_PATH = process.env.REACT_APP_SERVER_PATH;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const navigate = useNavigate()
  const API_PATH = `${SERVER_URL}leaves.php`;
  const [drivers, setdrivers] = useState()
  const [isload, setisload] = useState(true)
  const [showload, setshowload] = useState(false)
  const [data, setdata2] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'normal' }
    }).then((res) => {
      console.log(res.data)

      setdrivers(res.data)
      setdata2(res.data)
      setisload(false)
    })
      .catch((err) => console.log(err))

  }, [])

  function viewRow() {

  }


  let today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  today = yyyy + '-' + mm + '-' + dd;
  const [todaydate, settodaydate] = useState(today)

  const columns = [

    {
      name: <div className="datahead">Full name</div>,
      selector: "driver_name",
      sortable: true
    },
    {
      name: <div className="datahead">Branch</div>,
      selector: "branch",

      sortable: true
    },
    {
      name: <div className="datahead">Mobile</div>,
      selector: "phone",
      sortable: true
    }

    ,
    {
      name: <div className="datahead">Date Employed</div>,
      selector: "date_employed",
      sortable: true
    },


    {
      name: <div className="datahead">Leave Request</div>,
      selector: "appoval_status",
      cell: (row) => <div>
        {row.appoval_status == 0 ? (
          <p style={{ textAlign: 'left', color: 'red' }}>Pending</p>
        ) : null}

      </div>,
    }
    ,
    {
      name: <div className="datahead">Driver Status</div>,
      selector: "status",

      sortable: true
    }
    ,
    {
      name: "Action",
      cell: (row) => <div className="btn-group" role="group" aria-label="Basic example">
        &nbsp; &nbsp;
        <a id={row.id} onClick={() => setModalIsOpenToTrue(row.driver_id, row.id)} className="">{calender}</a>&nbsp; &nbsp;
      </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      selector: false
    },


  ];
  const tableData = {
    columns,
    data
  };



  const API_PATH1 = `${SERVER_URL}get_driver_details.php`;
  const [vacationdata, setvacationdata] = useState({
    leave_from: '',
    leave_to: '',
    notes: '',
    leave_type: '',
    driver_id: '',
    leave_form: '',
    settlement_form:'',
    appoval_status:''
  })
  const [joindata, setjoindata] = useState({
    driver_name: '',
    change_date: '',
    date_employed: ''
  })

  const [leaveformview, setleaveformview] = useState()
  const [settlementformview, setsettlementformview] = useState()
  const [msg, setmsg] = useState()
  function setModalIsOpenToTrue(val, val1) {
    
    axios({
      method: 'post',
      url: `${API_PATH1}`,
      data: val
    }).then((res) => {
      console.log(res.data)
      if (res.data.vacationdetails != null) {
        setvacationdata(res.data.vacationdetails)

        if(res.data.vacationdetails.leave_form!=='' && !!res.data.vacationdetails.leave_form){
          const leaveformpath=res.data.vacationdetails.leave_form.split('uploads/')
          
          setleaveformview(`${SERVER_PATH}/uploads/${leaveformpath[1]}`)
        }
  
        if(res.data.vacationdetails.settlement_form!=='' && !!res.data.vacationdetails.settlement_form){
          const settlement_formpath=res.data.vacationdetails.settlement_form.split('uploads/')
          
          setsettlementformview(`${SERVER_PATH}/uploads/${settlement_formpath[1]}`)
        }

      } else {
        setvacationdata({
          ...vacationdata, leave_from: '',
          leave_to: '',
          notes: '',
          leave_type: '',
          driver_id: '',
          leave_form: ''
        })

      }
      if (res.data.joindetails != null) {
        setjoindata(res.data.joindetails)
      }
      setmsg('')
      setModalIsOpen(true)

    }).catch(err => console.log(err.message))
  }

  function setModalIsOpenToFalse() {
    setModalIsOpen(false)
    if(msg!=''){
      window.location.reload();
    }
   
  }


  const [driver, setdriver] = useState()
  const [image_info, setimage_info] = useState({
    filedetails: {
      settlement: '',
      leave_form: ''
    }
  })
  function updatefiles(val, i, k) {

    const clonedListing = { ...image_info.filedetails };

    if (k == 'settlement') {
      setimage_info({
        filedetails: {
          ...clonedListing,
          settlement: val
        }
      });
    } else if (k == 'leave_form') {
      setimage_info({
        filedetails: {
          ...clonedListing,
          leave_form: val
        }
      });
    }
  }

  const API_UpdateLeave = `${SERVER_URL}leaves.php`
  function apply_leave(event,id) {
    event.preventDefault();
    if (vacationdata && vacationdata.appoval_status) {

      setshowload(true)
      axios({
        method: 'post',
        url: API_UpdateLeave,
        data: { val: id, approval_status: vacationdata.appoval_status, type: 'updateleave' }
      }).then((res) => {
        console.log(res.data)
        if (res.data > 1) {
          saveimages(event, res.data)
        }
      }).catch((err) => console.log(err.message))
    } else {
      setmsg("Please enter all the required fields")
    }
  }
  function saveimages(event, vacationid) {
    console.log(image_info.filedetails);
    event.preventDefault();
    const data = new FormData();
    data.append("leave_form[]", image_info.filedetails.leave_form);
    data.append("settlement[]", image_info.filedetails.settlement);
    data.append("vacationid", vacationid)
    data.append("passportno", joindata.passport_number)
    data.append("leaveform2", vacationdata.leave_form)
    data.append("settlementform2", vacationdata.settlement_form)

    let url = `${SERVER_URL}vacation_fileupload.php`;

    axios.post(url, data, {
      // receive two parameter endpoint url ,form data
    })
      .then((res) => {
        console.log('heree', res.data.msg)
        setshowload(false)
        //setmsg(res.data.msg)
        if(res.data.status!=1){
          setmsg(res.data.msg)
        }else{
          setmsg(res.data.msg)
          setTimeout(()=> {
            window.location.reload(); 
        }, 2000); 
         // navigate('/leave-applications')
        }
        setmsg(res.data.msg)
      }, error => {
        alert(error);


      });


  }




  return (

    <div className='wrapper-holder driver'>


      {<SidebarMenu />}



      <div className="main-panel ps-container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">

                <Modal isOpen={modalIsOpen} ariaHideApp={false}>

                  <>


                    <div className="modal-header">
                      <h4 className="modal-title">Apply Vacation / Leave </h4>
                      <button type="button" onClick={setModalIsOpenToFalse} className="close" data-dismiss="modal">×</button>
                    </div>
                    {/* Modal body */}
                    {/* <div className="modal-body"> */}
                    <div className="container-fluid modalcontainer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              <form id="myform" encType="multipart/form-data">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group" style={{ marginTop: '0px' }}>
                                      <label>Driver</label>
                                      <input type="text" id="driver" name="driver" defaultValue={joindata.driver_name} className="form-control" disabled />
                                      <input type="hidden" id="driver_id" name="driver_id" className="form-control" disabled defaultValue />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <label>Leave Type</label>
                                    <div className="form-group">
                                      <select id="leave_type" name="leave_type" onChange={(e) => setvacationdata({ ...vacationdata, leave_type: e.target.value })} defaultValue={vacationdata.leave_type && vacationdata.leave_type != '' ? vacationdata.leave_type : ''} className="form-control">
                                        <option>Select Leave Type</option>
                                        <option value="Sick Leave">Sick Leave</option>
                                        <option value="Emergency Leave">Emergency Leave</option>
                                        <option value="Annual Leave">Annual Leave</option>
                                        <option value="Local Leave">Local Leave</option>
                                        <option value="Maternity Leave">Maternity Leave</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group" style={{ marginTop: '13px' }}>
                                      <label>Leave From <span style={{ color: 'red' }}> * </span></label>
                                      <input type="date" className="start form-control" onChange={(e) => setvacationdata({ ...vacationdata, leave_from: e.target.value })} defaultValue={vacationdata.leave_from} id="start" name="start" />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group" style={{ marginTop: '13px' }}>
                                      <label>Leave To <span style={{ color: 'red' }}> * </span></label>
                                      <input type="date" className="end form-control" id="end" onChange={(e) => setvacationdata({ ...vacationdata, leave_to: e.target.value })} defaultValue={vacationdata.leave_to} name="end" />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group" style={{ marginTop: '13px' }}>
                                      <label>Joined Date</label>
                                      <input type="date" className="start form-control" id="joined_date" defaultValue={joindata.date_employed} name="joined_date" disabled />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group" style={{ marginTop: '13px' }}>
                                      <label>Change Status Date </label>
                                      <input type="date" className="end form-control" id="change_date" defaultValue={joindata.change_date} name="change_date" disabled />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label>Special Notes</label>
                                      <div className="form-group">
                                        <label className="bmd-label-floating"> </label>
                                        <textarea className="form-control" rows={3} onChange={(e) => setvacationdata({ ...vacationdata, notes: e.target.value })} name="notes" defaultValue={vacationdata.notes} id="notes" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <label>Approval Status</label>
                                    <div className="form-group">
                                      <select id="leave_type" name="leave_type" onChange={(e) => setvacationdata({ ...vacationdata, appoval_status: e.target.value })} defaultValue={vacationdata.appoval_status && vacationdata.appoval_status != '' ? vacationdata.appoval_status : ''} className="form-control">
                                        <option>Select Status</option>
                                        <option value="0">Pending</option>
                                        <option value="1">Approved</option>
                                        <option value="2">Rejected</option>

                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="w-100">Leave Form <span style={{ color: 'red' }}> * </span>
                                      <div className="custom-file">
                                        <input type="file" style={{ float: 'right' }} onChange={(e) => updatefiles(e.target.files[0], 1, 'leave_form')} />

                                      </div>
                                    </label>
                                    <input type="hidden" name="leave_form1" id="leave_form1" />
                                    <div className="view_form">
                                    </div>
                                    {
                                  vacationdata.leave_form && vacationdata.leave_form != '' ? (
                                    <a href={leaveformview} target="_blank" style={{color:'#7f3e98',fontWeight:'bold'}}>View Form</a>

                                  ) : null
                                }
                                  </div>
                                </div>

                                <div className="row">
                                
                                  <div className="col-md-6">
                                    <label className="w-100">Settlement Form 
                                      <div className="custom-file">
                                        <input type="file" style={{ float: 'right' }} onChange={(e) => updatefiles(e.target.files[0], 1, 'settlement')} />

                                      </div>
                                    </label>
                                    <input type="hidden" name="leave_form1" id="leave_form1" />
                                    <div className="view_form">
                                    </div>
                                    {
                                  vacationdata.settlement_form && vacationdata.settlement_form != '' ? (
                                    <a href={settlementformview} target="_blank" style={{color:'#7f3e98',fontWeight:'bold'}}>View Form</a>

                                  ) : null
                                }
                                  </div>
                                </div>
                               
                                <p id="sign" />
                                <a className="btn btn-primary pull-right" onClick={e => apply_leave(e,vacationdata.id)} style={{ color: '#ffffff !important' }}>Update Leave</a>
                                <div className="clearfix" />
                                {showload ? (
                                  <img src={loading} style={{ width: '5%', marginLeft: '11%' }} />
                                ) : null}
                                <p style={{fontWeight:'bold'}}>{msg}</p>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* Modal footer */}
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary closebtn" onClick={setModalIsOpenToFalse} >Close</button>

                    </div>
                  </>
                </Modal>

                {/* visa application  */}



                <div className="card">
                  <div className="card-header card-header-primary">
                    <h4 className="card-title">Leave Applications</h4>
                    <p className="card-category"></p>


                  </div>
                  <div className="card-body">
                    {isload ? (
                      <div style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>Please wait. The data is loading ! </p>

                      </div>
                    ) : (<DataTableExtensions {...tableData}>
                      <DataTable
                        columns={columns}
                        data={data}
                        noHeader
                        defaultSortField="id"
                        // sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      />
                    </DataTableExtensions>
                    )}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
