import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard';
import Adduser from './components/pages/Adduser';
import Header from './components/Header';
import Login from './components/pages/Login';
import AddDriver from './components/pages/AddDriver';
import Driverlist from './components/pages/Driverlist';
import ScrollToTop from './components/pages/ScrollToTop';
import Driver_Vehicles from './components/pages/Driver_Vehicles';
import EditDriver from './components/pages/EditDriver';
import RetiredDrivers from './components/pages/RetiredDrivers';
import Newjoiners from './components/pages/Newjoiners';
import Vehicles from './components/pages/Vehicles';
import AddVehicleType from './components/pages/AddVehicleType';
import VehicleTypeList from './components/pages/VehicleTypeList';
import EditVehicleType from './components/pages/EditVehicleType';
import OffVehicles from './components/pages/OffVehicles';
import AgingVehicles from './components/pages/AgingVehicles';
import FuelReport from './components/pages/FuelReport';
import FuelOverused from './components/pages/FuelOverused';
import DriverVsFuel from './components/pages/DriverVsFuel';
import KmReport from './components/pages/KmReport';
import SearchVehicle from './components/pages/SearchVehicle';
import SalikReport from './components/pages/SalikReport';
import TelephoneReport from './components/pages/TelephoneReport';
import Analytics from './components/pages/Analytics';
import AddVehicle from './components/pages/AddVehicle';
import EditVehicle from './components/pages/EditVehicle';
import LeaveApplications from './components/pages/LeaveApplications';
import VisaApplications from './components/pages/VisaApplications';
import AdminDashboard from './components/pages/AdminDashboard';
import AddStaff from './components/pages/AddStaff';
import StaffList from './components/pages/StaffList';
import EditStaff from './components/pages/EditStaff';
import StaffLeaveApplication from './components/pages/StaffLeaveApplication';
import MyVehicles from './components/pages/MyVehicles';
import Logout from './components/pages/Logout';
import General from './components/pages/General';
import UserList from './components/pages/UserList';
import UserEdit from './components/pages/UserEdit';
import RetrievePod from './components/pages/RetrievePod';
import PodList from './components/pages/PodList';
import PodView from './components/pages/PodView';
import UploadPod from './components/pages/UploadPod';
import Km_Fuel_Driver from './components/pages/Km_Fuel_Driver';
import DriverLeaves from './components/pages/DriverLeaves';
import AddDriverByLink from './components/pages/AddDriverByLink';
import ValidateDrivers from './components/pages/ValidateDrivers';
import LeaveFormLink from './components/pages/LeaveFormLink';

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
      <ScrollToTop/>
      <Routes>
      <Route path="/" element={<Login/>}></Route>
      <Route path="/dashboard" element={<Dashboard/>}></Route>
      <Route path="/add-user" element={<Adduser/>}></Route>
      <Route path="/user-list" element={<UserList/>}></Route>
      <Route path="/user-edit/*" element={<UserEdit/>}></Route>
      <Route path="/add-driver" element={<AddDriver/>}></Route>
      <Route path="/drivers" element={<Driverlist/>}></Route>
      <Route path="/view-vehicles/*" element={<Driver_Vehicles/>}></Route>
      <Route path="/view-leaves/*" element={<DriverLeaves/>}></Route>
      <Route path="/edit-driver/*" element={<EditDriver/>}></Route>
      <Route path="/ex-drivers" element={<RetiredDrivers/>}></Route>
      <Route path="/new-joiners" element={<Newjoiners/>}></Route>
      <Route path="/vehicles" element={<Vehicles/>}></Route>
      <Route path="/add-vehicle-type" element={<AddVehicleType/>}></Route>
      <Route path="/vehicle-type-list" element={<VehicleTypeList/>}></Route>
      <Route path="/edit-vehicle-type/*" element={<EditVehicleType/>}></Route>
      <Route path="/add-vehicle" element={<AddVehicle/>}></Route>
      <Route path="/edit-vehicle/*" element={<EditVehicle/>}></Route>
      <Route path="/off-vehicle" element={<OffVehicles/>}></Route>
      <Route path="/old-vehicles" element={<AgingVehicles/>}></Route>
      <Route path="/fuel-report" element={<FuelReport/>}></Route>
      <Route path="/fuel-overused" element={<FuelOverused/>}></Route>
      <Route path="/fuel-driver" element={<DriverVsFuel/>}></Route>
      <Route path="/fuel-report2" element={<General/>}></Route>
      <Route path="/fuel-overused2" element={<General/>}></Route>
      <Route path="/fuel-driver2" element={<General/>}></Route>
      <Route path="/km-report2" element={<General/>}></Route>
      <Route path="/km-report" element={<KmReport/>}></Route>
      <Route path="/km-fuel-driver2" element={<General/>}></Route>
      <Route path="/km-fuel-driver" element={<Km_Fuel_Driver/>}></Route>
      <Route path="/search-vehicle2" element={<General/>}></Route>
      <Route path="/search-vehicle" element={<SearchVehicle/>}></Route>
      <Route path="/salik-report" element={<SalikReport/>}></Route>
      <Route path="/telephone-report" element={<TelephoneReport/>}></Route>
      <Route path="/analytics" element={<Analytics/>}></Route>
      <Route path="/leave-applications" element={<LeaveApplications/>}></Route>
      <Route path="/visa-applications" element={<VisaApplications/>}></Route>
      <Route path="/admin-dashboard" element={<AdminDashboard/>}></Route>
      <Route path="/add-staff" element={<AddStaff/>}></Route>
      <Route path="/staff-list" element={<StaffList/>}></Route>
      <Route path="/edit-staff/*" element={<EditStaff/>}></Route>
      <Route path="/staff-leave-applications" element={<StaffLeaveApplication/>}></Route>
      <Route path="/my-vehicles" element={<MyVehicles/>}></Route>
      <Route path="/upload-pod" element={<UploadPod/>}></Route>
      <Route path="/retrieve-pod" element={<RetrievePod/>}></Route>
      <Route path="/pod-list/*" element={<PodList/>}></Route>
      <Route path="/pod-view/*" element={<PodView/>}></Route>
      <Route path="/logout" element={<Logout/>}></Route>

      <Route path="/driver-form/*" element={<AddDriverByLink/>}></Route>
      <Route path="/validate-driver" element={<ValidateDrivers/>}></Route>
      <Route path="/leave-form/*" element={<LeaveFormLink/>}></Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
