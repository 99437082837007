import axios from "axios";
import { useEffect } from "react";
import SidebarMenu from "../SidebarMenu";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar,faClose,faCalendarDays,faAddressCard,faPenToSquare, faBatteryEmpty} from '@fortawesome/free-solid-svg-icons'


const deleteicon = <FontAwesomeIcon icon={faClose}  style={{color:''}}/>

const PodList = () => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const role = sessionStorage.getItem('role');
    const user = sessionStorage.getItem('userid');   
    const url=window.location.href;
    const get_id = url.split("/").pop();

    useEffect(() => {
        getPodList();
    }, []);

    const [isload, setisload] = useState(true);
    const [data, setdata] = useState([]);
    const getPodList = async () => {
        const API_PATH = `${SERVER_URL}pod.php`;
        const passval = {
            type: "podlist",
            manager: get_id
        };
        await axios
            .post(API_PATH, passval)
            .then(res => {
                console.log(res);
                setdata(res.data)
                setisload(false);  
            })
            .catch((err) => {
                console.log(err);
            })
    }
    
    const columns = [
        {
            name: "Account",
            selector: row => row.branch,
            sortable: true,
        },
        {
            name: "Manager",
            selector: row => row.user,
            sortable: true,
        },
        {
            name: "AWB Date",
            selector: row => row.updated_on,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => <>
                <Link to={`/pod-view/${row.id}`} ><button className="btn btn-primary" style={{padding: '6px 12px'}}>View POD</button></Link>
                {/* <a className="btn btn-danger" onClick={(e) => getDeleteuser(e.target.value)} value={row.id} style={{ color: '#fff' }}>Delete</a> */}
                <a className="btn btn-danger" value={row.id} onClick={(e) => getDeleteuser(e.target.attributes.getNamedItem("value").value)} style={{ color: '#fff',padding: '6px 12px' }}>{deleteicon}</a>
            </>,
        },
    ]

    const tableData = {
      columns,
      data
    };

    function getDeleteuser(val){
        // alert(val);
        window.confirm("Are you sure to delete this record!")
        ?condeleteuser(val)
        :condeleteuser(0)
    }

    const condeleteuser = async (val) => {
        if(val > 0) {
            const API_PATH = `${SERVER_URL}pod.php`;
            const passval = {
                deleteid: val,
                type: 'deleterecord'
            };
            await axios
                .post(API_PATH, passval)
                .then(res => {
                    console.log(res);
                    if(res.data == '1'){
                        window.location.reload();
                    }else{
                        alert("Delele Recoed Failed!, Please try again!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }


    return(
        <div className="wrapper-holder">
            <SidebarMenu/>
            
            <div className="main-panel ps-container">                
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">   

                                    <div className="card-header card-header-primary">
                                        <h4 className="card-title ">POD's <span style={{ float: 'right' }}> <Link to="/upload-pod">+</Link></span></h4>
                                        <p className="card-category"></p>
                                    </div>
                                    <div className="card-body">
                                        {
                                            isload?(
                                                <div style={{textAlign:"center"}}>
                                                    <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>
                                                </div>
                                            ):(
                                                <DataTableExtensions {...tableData}>
                                                    <DataTable 
                                                        columns={columns}
                                                        data={data}
                                                        noHeader
                                                        defaultSortField="id"
                                                        // sortIcon={<SortIcon />}
                                                        defaultSortAsc={true}
                                                        pagination
                                                        highlightOnHover
                                                        dense
                                                    />
                                                </DataTableExtensions>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PodList;