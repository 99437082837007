import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import SidebarMenu from '../SidebarMenu';
import Select from 'react-select';


export default function Adduser() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')  

  const [listBranch, setlistBranch] = useState();
  const getBranchlist = async () => {
    const API_PATH = `${SERVER_URL}user.php`;
    const passval = {
      type: "branch-list",
      user: user
    };

    await axios
      .post(API_PATH, passval)
      .then(res => {
        // console.log(res);
        setlistBranch(res.data);

      })
      .catch((err) => {
        console.log(err);
      })
  }


  const options = listBranch?.map(data => ({
    label: `${data.branch}`,
    value: `${data.id}`
  }))

  // const [selectbranch, setselectbranch] = useState();
  var selectMultiChange = (e) => {
    // setselectbranch(Array.isArray(e)?e.map(data=>data.value):[]);
    setadduser({...adduser,branch:Array.isArray(e)?e.map(data=>data.value):[]})
  }

  useEffect(() => {
    getBranchlist();
  }, []);

  const defaultValues = {
    name: '',
    username: '',
    password: '',
    email: '',
    phone: '',
    branch: ''
  }



  const [adduser, setadduser] = useState(defaultValues);
  const [err_msg, set_err_msg] = useState();
  const submit_user = async () => {
    const API_PATH = `${SERVER_URL}user.php`;
    // console.log(adduser);

    let countError = 0, fieldLoop = adduser;

 let lastAtPos = adduser.email.lastIndexOf("@");
	  let lastDotPos = adduser.email.lastIndexOf(".");
    Object.entries(fieldLoop).map( ( [key, value] ) => {
      if ( value==='' ){
        countError++;
        set_err_msg("Please enter all the required field!");
        return false; // stop here...     
      }  
    });

    if (!(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      adduser.email.indexOf("@@") == -1 &&
      lastDotPos > 2 &&
      adduser.email.length - lastDotPos > 2
    )){
      set_err_msg('Please enter a valid email id');
      countError++;
      return false; // stop here... 
    }
    if(countError > 0){
      return false; // stop here...
    }else{
      const passval = {
        type: 'adduser',
        name: adduser.name,
        username: adduser.username,
        password: adduser.password,
        email: adduser.email,
        phone: adduser.password,
        branch: adduser.branch,
        user: user
      };

      await axios
        .post(API_PATH, passval)
        .then(res =>{
          console.log(res);
          if(res.data.status == '1'){
            set_err_msg(res.data.message);  
              setTimeout(()=> {
                  window.location.reload(); 
              }, 2000);  
          }else{
            set_err_msg(res.data.message); 
          }   
        })
        .catch((err) => {
          console.log(err);
        })
    }

  }


  return (
    <div className='wrapper-holder'>
    {<SidebarMenu />}
    <div className="main-panel ps-container">
    <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header card-header-primary">
                  <h4 className="card-title">Add User</h4>
                  <p className="card-category">Complete your profile</p>
                </div>
                <div className="card-body">
                  <form id="myform" encType="multipart/form-data">                    

                    <div className="row">
                        <div className='col-md-4'>
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text"  id="name" name="name" value={adduser.name} onChange={(e)=>setadduser({...adduser,name:e.target.value})} className="form-control"></input>
                          </div>
                        </div>

                        <div className='col-md-4'>                          
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Username <span style={{ color: 'red' }}>*</span></label>
                            <input type="text"  id="username" name="username" value={adduser.username} onChange={(e)=>setadduser({...adduser,username:e.target.value})} className="form-control"></input>
                          </div>
                        </div>

                        <div className='col-md-4'>                          
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Password <span style={{ color: 'red' }}>*</span></label>
                            <input type="text"  id="password" name="password" value={adduser.password} onChange={(e)=>setadduser({...adduser,password:e.target.value})} className="form-control"></input>
                          </div>
                        </div>

                        <div className='col-md-4'>                         
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Email <span style={{ color: 'red' }}>*</span></label>
                            <input type="email"  id="email" name="email" value={adduser.email} onChange={(e)=>setadduser({...adduser,email:e.target.value})} className="form-control"></input>
                          </div>
                        </div>

                        <div className='col-md-4'>                         
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Phone <span style={{ color: 'red' }}>*</span></label>
                            <input type="text"  id="phone" name="phone" value={adduser.phone} onChange={(e)=>setadduser({...adduser,phone:e.target.value})} className="form-control"></input>
                          </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="bmd-label-floating" style={{ marginTop: '5px', width: '100%', textAlign: 'left' }}>Branch <span style={{ color: 'red' }}>*</span></label>
                                
                                <Select
                                  isMulti
                                  onChange={selectMultiChange}
                                  options={options}
                                />         

                                {/* <select id="branch" name="branch" onChange={(e)=>setadduser({...adduser,branch:e.target.value})} className="form-control " data-live-search="true"> */}
                                {/* <select id="branch" name="branch" onChange={(e)=>getBranchId(e.target.value)} className="form-control " data-live-search="true">                                   
                                    <option value="" selected={adduser.branch===''?true:false}>Choose Branch</option>
                                    {
                                      listBranch && listBranch !=""?(
                                        <>
                                          {
                                            listBranch.map(data => (
                                              <option value={data.id}>{data.branch}</option>
                                            ))
                                          }
                                        </>
                                      ):null
                                    }
                                </select> */}
                            </div>
                        </div>

                        <div className="col-md-12" style={{textAlign: 'right'}}>
                            <p style={{ color: "red" }}>{err_msg}</p>
                            <a className="btn btn-primary pull-right" onClick={submit_user} style={{color: '#fff'}}>Add User</a>
                        </div>
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  )
}
