import React, { useEffect, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faPhone, faSignIn, fagasPumpSlash, faCarSide, faUser, faUsers, faUserXmark, faGasPump } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
const usericon = <FontAwesomeIcon icon={faUser} />
const users = <FontAwesomeIcon icon={faUsers} />
const vehicle = <FontAwesomeIcon icon={faCar} />
const onleave = <FontAwesomeIcon icon={faUserXmark} />
const carpark = <FontAwesomeIcon icon={faCarSide} />
const fuel = <FontAwesomeIcon icon={faGasPump} />
export default function AdminDashboard() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const user=sessionStorage.getItem('userid');
  const branch=sessionStorage.getItem('branch');
  const user_role=sessionStorage.getItem('role');
//const API_PATH=`${SERVER_URL}dashboard.php`;
const [fleetdata, setfleetdata] = useState()
const [vehlimit, setlimit] = useState(0)
const [checklimit, setchecklimit] = useState(false)
let today = new Date();
let dd = today.getDate();

let mm = today.getMonth()+1; 
let yyyy = today.getFullYear();
if(dd<10) 
{
    dd='0'+dd;
} 

if(mm<10) 
{
    mm='0'+mm;
} 
today = yyyy+'-'+mm+'-'+dd;
const [todaydate, settodaydate] = useState(today)
const [thismonth, setthismonth] = useState(mm+' - '+yyyy)
 const API_PATH=`${SERVER_URL}staff_dashboard.php`;

  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: {user:user,branch: branch,user_role:user_role,type:'basic'}
    })
    .then((res)=>{
      console.log(res.data)
      setfleetdata(res.data)
      
    }).catch(error => console.log(error.message));
  }, [])


  const [licenseexpiry, setlicenseexpiry] = useState()
  const [licenseexpirycount, setlicenseexpirycount] = useState(0)
  const [licenseexpired, setlicenseexpired] = useState()
  const [licenseexpiredcount, setlicenseexpiredcount] = useState(0)
  const [visaexpiry, setvisaexpiry] = useState()
  const [visaexpirycount, setvisaexpirycount] = useState(0)
  const [visaexpired, setvisaexpired] = useState()
  const [visaexpiredcount, setvisaexpiredcount] = useState(0)
  const [passportexpiry, setpassportexpiry] = useState()
  const [passportexpirycount, setpassportexpirycount] = useState(0)
  const [leaseexpiry, setleaseexpiry] = useState()
  const [leaseexpirycount, setleaseexpirycount] = useState(0)
  const [leaveoverdue, setleaveoverdue] = useState()
  const [leaveoverduecount, setleaveoverduecount] = useState(0)
  const [newjoinees, setnewjoinees] = useState()
  const [newjoineescount, setnewjoineescount] = useState(0)
  const [showdriver, setshowdriver] = useState(false)
  const [showvehicles, setshowvehicles] = useState(false)
  const [showleaves, setshowleaves] = useState(false)
  const API_PATH2='http://localhost:8000/staff_dashboard.php';

  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH2}`,
      data: {user:user,branch: branch,user_role:user_role,type:'notification'}
    })
    .then((res)=>{
      console.log('here',res.data.licenseexpiry)
     
        setpassportexpiry(res.data.passportexpiry)
        setpassportexpirycount(res.data.passportexpiry.length)
        setvisaexpiry(res.data.visaexpiry)
        setvisaexpirycount(res.data.visaexpiry.length)
        setvisaexpired(res.data.visaexpired)
        setvisaexpiredcount(res.data.visaexpired.length)
        setleaveoverdue(res.data.leaveoverdue)
        setleaveoverduecount(res.data.leaveoverdue.length)
        setnewjoinees(res.data.newjoinees)
        setnewjoineescount(res.data.newjoinees.length)
     
    //   setlicenseexpired(res.data.licenseexpired)
    //   setlicenseexpiredcount(res.data.licenseexpired.length)
    //   setvisaexpiry(res.data.visaexpiry)
    //   setvisaexpirycount(res.data.visaexpiry.length)
    //   setpassportexpiry(res.data.passportexpiry)
    //   setpassportexpirycount(res.data.passportexpiry.length)
    //   setleaseexpiry(res.data.leaseexpiry)
    //   setleaseexpirycount(res.data.leaseexpiry.length)
    //   setleaveoverdue(res.data.leaveoverdue)
    //   setleaveoverduecount(res.data.leaveoverdue.length)
  
       setshowdriver(true)
      
    }).catch(error => console.log(error.message));
  }, [])
  
function changefun(val){
if(val=='driver'){
  setshowdriver(true)
 
  setshowleaves(false)
}

if(val=='leaves'){
  setshowleaves(true)
  setshowdriver(false)

}
}

  return (
    <div className='wrapper-holder'>
      {<SidebarMenu />}
      <div className="main-panel ps-container">
        <div className="content">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card card-stats">
                    <div className="card-header card-header-warning card-header-icon">
                      <div className="card-icon">
                        {users}
                      </div>
                    
                      <p className="card-category">Staffs  </p>
                      <h3 className="card-title">{fleetdata?fleetdata.staffcount:0}
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <i className="material-icons text-danger" />
                        <a href="<?=base_url();?>drivers">Total Staffs </a>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card card-stats">
                    <div className="card-header card-header-danger card-header-icon">
                      <div className="card-icon">
                        {onleave}
                      </div>
                      <p className="card-category">On Leave</p>
                      <h3 className="card-title">{fleetdata?fleetdata.staffleavescount:0}</h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <i className="material-icons text-danger" />
                        <a href="<?=base_url();?>drivers">On leave</a>
                      </div>
                    </div>
                  </div>
                </div>
            
              
             
              </div></div></div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="card">
                <div className="card-header card-header-tabs card-header-primary">
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <span className="nav-tabs-title">Tasks:</span>
                      <ul className="nav nav-tabs" data-tabs="tabs">
                        <li className="nav-item">
                          {showdriver?(<a className="nav-link active"  style={{cursor:'pointer'}} onClick={()=>changefun('driver')} data-toggle="tab">
                            <i className="fa fa-users" /> Staffs
                            <div className="ripple-container" />
                          </a>):(<a className="nav-link "  style={{cursor:'pointer'}} onClick={()=>changefun('driver')} data-toggle="tab">
                            <i className="fa fa-users" /> Staffs
                            <div className="ripple-container" />
                          </a>)}
                          
                        </li>
                        <li className="nav-item">
                          {
                            showvehicles?(   <a className="nav-link active"  style={{cursor:'pointer'}}  onClick={()=>changefun('leaves')} data-toggle="tab">
                            <i className="fa fa-car" /> Leaves
                            <div className="ripple-container" />
                          </a>):(   <a className="nav-link"  style={{cursor:'pointer'}}  onClick={()=>changefun('leaves')} data-toggle="tab">
                            <i className="fa fa-car" /> Leaves
                            <div className="ripple-container" />
                          </a>)
                          }
                       
                        </li>
                      
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tab-content">
                    <div className="tab-pane active" id="drivers">
                   
                           
                      <table className="table">
                        <tbody>
                        
                        {showdriver?(<>
                             {[...Array(licenseexpirycount)].map((e, i) => (
                              licenseexpiry && licenseexpiry!=""?(
                                <>
                                
                                <tr key={i}>
                            
                            <td style={{ color: '#000',textAlign:'left',fontSize:'14px'}}>{licenseexpiry[i].first_name} {licenseexpiry[i].last_name} license will expire on {licenseexpiry[i].license_expiry_date}</td>
                          </tr>            
    </>
                              ):null
       


                              )
                              )}

{[...Array(licenseexpiredcount)].map((e, i) => (
                              licenseexpired && licenseexpired!=""?(
                                <>
                                
                                <tr key={i}>
                            
                            <td style={{ color: 'red',textAlign:'left',fontSize:'14px'}}>{licenseexpired[i].first_name} {licenseexpired[i].last_name} license expired on {licenseexpired[i].license_expiry_date}</td>
                          </tr>            
    </>
                              ):null
       


                              )
                              )}

{[...Array(visaexpirycount)].map((e, i) => (
                              visaexpiry && visaexpiry!=""?(
                                <>
                                
                                <tr key={i}>
                            
                            <td style={{textAlign:'left',fontSize:'14px'}}>{visaexpiry[i].first_name} {visaexpiry[i].last_name} visa expiry on  {visaexpiry[i].work_permit_expiry}</td>
                          </tr>            
    </>
                              ):null
       


                              )
                              )}
{[...Array(visaexpiredcount)].map((e, i) => (
                              visaexpired && visaexpired!=""?(
                                <>
                                
                                <tr key={i}>
                            
                            <td style={{textAlign:'left',fontSize:'14px',color:'red'}}>{visaexpired[i].first_name} {visaexpired[i].last_name} visa expired  on  {visaexpired[i].work_permit_expiry}</td>
                          </tr>            
    </>
                              ):null
       


                              )
                              )}
{[...Array(passportexpirycount)].map((e, i) => (
                              passportexpiry && passportexpiry!=""?(
                                <>
                                
                                <tr key={i}>
                            {todaydate>=passportexpiry[i].pdrp_expiry_date?(
                                 <td style={{textAlign:'left',fontSize:'14px',color:"red"}}>{passportexpiry[i].first_name} {passportexpiry[i].last_name} passport expired on  {passportexpiry[i].pdrp_expiry_date}</td>
                         
                            ):(
   <td style={{textAlign:'left',fontSize:'14px'}}>{passportexpiry[i].first_name} {passportexpiry[i].last_name} passport expiry on  {passportexpiry[i].pdrp_expiry_date}</td>
                         
                            )}
                          </tr>            
    </>
                              ):null
       


                              )
                              )}
                       
                       </>
                       
                            ):null}                 
                       
 

{showleaves?(<>
    {[...Array(leaveoverduecount)].map((e, i) => (
                              leaveoverdue && leaveoverdue!=""?(
                                <>
                                
                                <tr key={i}>
                                {todaydate>=leaveoverdue[i].leave_to?(
                            <td style={{ color: 'red',textAlign:'left',fontSize:'14px'}}>{leaveoverdue[i].first_name} {leaveoverdue[i].last_name} was expected to rejoin by {leaveoverdue[i].leave_to}</td>
                                ):(
                                  <td style={{ color: '#000',textAlign:'left',fontSize:'14px'}}>{leaveoverdue[i].first_name} {leaveoverdue[i].last_name} was expected to rejoin by {leaveoverdue[i].leave_to}</td>
                               
                                )
                                }
                            </tr>            
    </>
                              ):null
       


                              )
                              )}
   </>

   ):(
    <>
    </>

   )}
                          </tbody>
                          </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
        <div className="card">
          <div className="card-header card-header-warning">
            <h4 className="card-title">Employees Status</h4>
            <p className="card-category" style={{color: '#fff !important', fontWeight: '400!important'}}>New employees on {thismonth}</p>
          </div>
          <div className="card-body table-responsive">
            <table className="table table-hover">
              <thead className="text-warning">
                <tr><th>ID</th>
                  <th>Name</th>
                  <th>Nationality</th>
                  <th>Joined date</th>
                </tr></thead>
              <tbody>
              {[...Array(newjoineescount)].map((e, i) => (
                              newjoinees && newjoinees!=""?(
                                <>
                                
                                <tr key={i}>
                              
                  <td>{i}</td>
                  <td>{newjoinees[i].first_name} {newjoinees[i].last_name}</td>
                  <td>{newjoinees[i].citizenship}</td>
                  <td>{newjoinees[i].date_employed}</td>
                </tr>          
    </>
                              ):null
       


                              )
                              )}
               
              </tbody>
            </table>
          </div>
        </div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
