import axios from "axios";
import { useEffect, useState } from "react";
import SidebarMenu from "../SidebarMenu";
import { useNavigate } from 'react-router-dom';

const EditVehicle = () => { 
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const role = sessionStorage.getItem('role');
    const user = sessionStorage.getItem('userid');    
    const navigate=useNavigate();
    const url=window.location.href;
    const vehicletypeid = url.split("/").pop();

    useEffect(() => {
        getVehicleType("list");
        getEdiVehicleDetails(vehicletypeid);
    }, []);

    const getEdiVehicleDetails = async (type ="") => {
        const API_PATH = `${SERVER_URL}vehicle.php`;
        const passeditvd_id = {
            type: "editvehicle",
            vehicle_id: type.toString()
        };
        await axios
            .post(API_PATH, passeditvd_id)
            .then((res) => {
                // console.log(res);   
                const getedit = res.data.find((s) => s.id.toString() === vehicletypeid);
                if(getedit.purchase_type == "Lease"){
                    setleaseclass('addv_active');                    
                    setvehicledata({...vehicledata, 
                        veh_type: getedit.veh_type, 
                        veh_gear: getedit.vehicle_gear,                   
                        make: getedit.make,                   
                        brand: getedit.brand,                   
                        vehicle_name: getedit.vehicle_name,                   
                        model: getedit.model,
                        reg_emirate: getedit.reg_emirate,
                        plate_number: getedit.plate_number,
                        reg_number: getedit.reg_number,
                        initial_mileage: getedit.initial_mileage,
                        purchase_type: getedit.purchase_type,
                        purchase_company: getedit.purchase_company,
                        lease_from: getedit.purchase_from,
                        lease_to: getedit.purchase_to,
                        mileage: getedit.mileage,
                        notes: getedit.notes,
                        status: getedit.status,
                        plan:getedit.plan
                    }) 
                }else if(getedit.purchase_type == "Rental"){
                    setrentalclass('addv_active');            
                    setvehicledata({...vehicledata, 
                        veh_type: getedit.veh_type, 
                        veh_gear: getedit.vehicle_gear,                   
                        make: getedit.make,                   
                        brand: getedit.brand,                   
                        vehicle_name: getedit.vehicle_name,                   
                        model: getedit.model,
                        reg_emirate: getedit.reg_emirate,
                        plate_number: getedit.plate_number,
                        reg_number: getedit.reg_number,
                        initial_mileage: getedit.initial_mileage,
                        purchase_type: getedit.purchase_type,
                        purchase_company: getedit.purchase_company,
                        rental_from: getedit.purchase_from,
                        rental_to: getedit.purchase_to,
                        mileage: getedit.mileage,
                        notes: getedit.notes,
                        status: getedit.status,
                        plan:getedit.plan
                    }) 
                }else{
                    setotherclass('addv_active');
                    setvehicledata({...vehicledata,
                        veh_type: getedit.veh_type, 
                        veh_gear: getedit.vehicle_gear,                     
                        make: getedit.make,                   
                        brand: getedit.brand,                
                        vehicle_name: getedit.vehicle_name,                   
                        model: getedit.model,
                        reg_emirate: getedit.reg_emirate,
                        plate_number: getedit.plate_number,
                        reg_number: getedit.reg_number,
                        initial_mileage: getedit.initial_mileage,
                        purchase_type: getedit.purchase_type,
                        purchase_company: getedit.purchase_company,
                        replace_from: getedit.purchase_from,
                        replace_to: getedit.purchase_to,
                        mileage: getedit.mileage,
                        notes: getedit.notes,
                        status: getedit.status,
                        plan:getedit.plan
                    })
                }  
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const [vehiclecategory, setvecateg] = useState();
    const getVehicleType = async (type ="") => {
        const API_PATH = `${SERVER_URL}vehicle.php`;
        const passval = {
            type: type.toString()
        };
        await axios
            .post(API_PATH, passval)
            .then((res) => {
                setvecateg(res.data);
                // console.log(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    
    
    const defaultValues = {
        veh_type: '',
        veh_gear: '',
        make: '',
        vehicle_name: '',
        model: '',
        reg_emirate: '',
        plate_number: '',
        purchase_type: '',
        purchase_company: '',
        reg_number: '',
        initial_mileage: '',
        mileage: '',
        rental_from: '',
        rental_to: '',
        lease_from: '',
        lease_to: '',
        notes: '',
        plan:''
    }
    const [msgcolor, setmsgcolor] = useState('green')
    const [vehicledata, setvehicledata] = useState(defaultValues);
    const [veh_type_err, setveh_type_err] = useState();
    const submit_vehicle = async () => {
        // console.log(vehicledata);
        let countError = 0;
    
        if(vehicledata.veh_type == "" || vehicledata.veh_gear == "" || vehicledata.make == "" || vehicledata.vehicle_name == "" || vehicledata.reg_emirate == "" || vehicledata.plate_number == "" || vehicledata.reg_number == "" || vehicledata.initial_mileage == "" || vehicledata.mileage == "" || !vehicledata.plan || vehicledata.plan.length < 4 ){
            setmsgcolor('red')
            setveh_type_err("Please enter all the required field!");
            countError++;   
            return false; // stop here...            
        }
        
        if(vehicledata.reg_number.length < 5 || vehicledata.reg_number.length > 5){ 
            setmsgcolor('red')
            setveh_type_err("Registartion number must be 5 digit.");           
            countError++; 
            return false; // stop here...
        }

        if( countError>0 ){            
            return false; // stop here...
        }else{
            setveh_type_err("Processing..."); // loader");

            const API_PATH = `${SERVER_URL}vehicle.php`;
            const addveh = {
                type : "updatevehicle",
                veh_type: vehicledata.veh_type,
                veh_gear: vehicledata.veh_gear,
                make: vehicledata.make,
                vehicle_name: vehicledata.vehicle_name,
                model: vehicledata.model,
                reg_emirate: vehicledata.reg_emirate,
                plate_number: vehicledata.plate_number,
                purchase_type: vehicledata.purchase_type,
                purchase_company: vehicledata.purchase_company,
                reg_number: vehicledata.reg_number,
                initial_mileage: vehicledata.initial_mileage,
                mileage: vehicledata.mileage,
                rental_from: vehicledata.rental_from,
                rental_to: vehicledata.rental_to,
                lease_from: vehicledata.lease_from,
                lease_to: vehicledata.lease_to,
                replace_from: vehicledata.replace_from,
                replace_to: vehicledata.replace_to,
                notes: vehicledata.notes,
                id: vehicletypeid,
                userid: user,
                plan: vehicledata.plan

            };

            await axios
                .post(API_PATH, addveh)
                .then(res => {
                    // console.log(res);
                    if(res.data.status == '1'){
                        setmsgcolor('green')
                        setveh_type_err(res.data.message);  
                        setTimeout(()=> {
                            navigate('/vehicles'); 
                        }, 2000);  
                    }else{
                        setmsgcolor('red')
                        setveh_type_err(res.data.message); 
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        } 
    }

    function getVehType(val){
        // console.log(val);
        setvehicledata({...vehicledata,veh_type:val})
        getVehicleBrand(val)
        // setvehicledata({...vehicledata,                   
        //     make: ''
        // })
    }

    const [vehiclebrand, setvehiclebrand] = useState();
    const getVehicleBrand = async (brand_id = "") => {
        // console.log(brand_id);
        const API_PATH = `${SERVER_URL}vehicle.php`;
        const passbrandid = {
            type : "getbrandname",
            brand_id: brand_id.toString()
        };
        await axios
            .post(API_PATH, passbrandid)
            .then(res => {
                // console.log(res);
                setvehiclebrand(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function getVehMake(val){
        // console.log(val);
        setvehicledata({...vehicledata,make:val})
        getVehicleMake(val);
    }

    const [vehiclename, setvehiclename] = useState();
    const getVehicleMake = async (make_id = "") => {        
        const API_PATH = `${SERVER_URL}vehicle.php`;
        const passmakeid = {
            type : "getmakename",
            make_id: make_id.toString()
        };
        await axios
        .post(API_PATH, passmakeid)
        .then(res =>{
            // console.log(res);
            setvehiclename(res.data);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //Year Model
    var todayYear = new Date().getFullYear();
    var rowsyear = [];
    for(var i = 2016; i <= todayYear; i++){
        rowsyear.push(<option value={i} selected={i==vehicledata.model?true:false}>{i}</option>)
    }

    const [leaseclass, setleaseclass] = useState();
    const [rentalclass, setrentalclass] = useState();
    const [otherclass, setotherclass] = useState();
    function getRLR(val){
        //console.log(val);
        setvehicledata({...vehicledata,purchase_type:val})        
        setleaseclass('');
        setrentalclass('');
        setotherclass('');
        if(val == "Lease"){
            setleaseclass('addv_active');
        }else if(val == "Rental"){
            setrentalclass('addv_active');
        }else{
            setotherclass('addv_active');
        }
    }

    return(
        <div className="wrapper-holder">
            {<SidebarMenu/>}

            <div className="main-panel ps-container">
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    
                                    <div className="card-header card-header-primary">
                                        <h4 className="card-title">Upate Vehicle</h4>
                                        <p className="card-category">Complete vehicle profile</p>                                     
                                    </div>

                                    <div className="card-body">
                                        <form id="myform" encType="multipart/form-data">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Vehicle Category <span style={{ color: 'red' }}>*</span></label>
                                                        {/* <select id="veh_type" name="veh_type" onChange={(e)=>setvehicledata({...vehicledata,veh_type:e.target.value})} className="form-control " data-live-search="true"> */}
                                                        <select id="veh_type" name="veh_type" onChange={(e) => getVehType(e.target.value)} className="form-control " data-live-search="true"> 
                                                        {/* <select className="form-control" data-live-search="true"> */}
                                                            <option value="" selected={vehicledata.veh_type===''?true:false}>Choose Vehicle Category</option>
                                                            {
                                                                vehiclecategory && vehiclecategory != ""?(
                                                                    <>
                                                                        {
                                                                            vehiclecategory.map(data => (
                                                                                <option value={data.id} selected={data.id===vehicledata.veh_type?true:false}>{data.vehicle_type}</option>
                                                                            ))
                                                                        }
                                                                    </>
                                                                ):null
                                                            }
                                                        </select>                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Vehicle Gear <span style={{ color: 'red' }}>*</span></label>
                                                        <select id="veh_gear" name="veh_gear" onChange={(e)=>setvehicledata({...vehicledata,veh_gear:e.target.value})} className="form-control " data-live-search="true">
                                                            <option value="" selected={vehicledata.veh_gear===''?true:false}>Choose Vehicle Gear</option>
                                                            <option value="Automatic" selected={'Automatic'===vehicledata.veh_gear?true:false}>Automatic</option>
                                                            <option value="Manual" selected={'Manual'===vehicledata.veh_gear?true:false}>Manual</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Vehicle Brand <span style={{ color: 'red' }}>*</span></label>
                                                        {/* <select id="make" name="make" onChange={(e)=>setvehicledata({...vehicledata,make:e.target.value})} className="form-control " data-live-search="true"> */}
                                                        <select id="make" name="make" onChange={(e) => getVehMake(e.target.value)}  className="form-control " data-live-search="true">
                                                            <option value="" selected={vehicledata.make===''?true:false}>Choose Vehicle Type</option>
                                                            
                                                            
                                                            {
                                                                vehiclebrand && vehiclebrand != ""?(
                                                                    <>
                                                                        {
                                                                            vehiclebrand.map(data2 => (
                                                                                <option value={data2.id}>{data2.brand}</option>
                                                                            ))
                                                                        }
                                                                    </>
                                                                ):<option value="{vehicledata.make}" selected={vehicledata.make}>{vehicledata.brand}</option>
                                                            }
                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Vehicle Name <span style={{ color: 'red' }}>*</span></label>
                                                        <select id="vehicle_name" name="vehicle_name" onChange={(e)=>setvehicledata({...vehicledata,vehicle_name:e.target.value})} className="form-control " data-live-search="true">
                                                            <option value="" selected={vehicledata.vehicle_name===''?true:false}>Choose Vehicle</option>
                                                         
                                                            {
                                                                vehiclename && vehiclename != ""?(
                                                                    <>
                                                                        {
                                                                            vehiclename.map(data3 => (
                                                                                <option value={data3.vehicle_name}>{data3.vehicle_name}</option>
                                                                            ))
                                                                        }
                                                                    </>
                                                                ):<option value="{vehicledata.vehicle_name}" selected={vehicledata.vehicle_name}>{vehicledata.vehicle_name}</option>
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Model Year</label>
                                                        <select id="vehicle_modelname" name="model" onChange={(e)=>setvehicledata({...vehicledata,model:e.target.value})} className="form-control " data-live-search="true">
                                                            <option value="" selected={vehicledata.model===''?true:false}>Choose Model Year</option>
                                                            {rowsyear}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Reg.Emirate <span style={{ color: 'red' }}>*</span></label>
                                                        <select id="reg_emirate" name="reg_emirate" onChange={(e)=>setvehicledata({...vehicledata,reg_emirate:e.target.value})} className="form-control " data-live-search="true">
                                                            <option value="" selected={vehicledata.reg_emirate===''?true:false}>Choose reg </option>
                                                            <option value="DXB" selected={'DXB'===vehicledata.reg_emirate?true:false}>DXB</option>
                                                            <option value="AUH" selected={'AUH'===vehicledata.reg_emirate?true:false}>AUH</option>
                                                            <option value="SHJ" selected={'SHJ'===vehicledata.reg_emirate?true:false}>SHJ</option>
                                                            <option value="RAK" selected={'RAK'===vehicledata.reg_emirate?true:false}>RAK</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Plate Number <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text"  id="plate_number" name="plate_number" value={vehicledata.plate_number} onChange={(e)=>setvehicledata({...vehicledata,plate_number:e.target.value})} className="form-control"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Reg Number <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text"  id="reg_number" name="reg_number" value={vehicledata.reg_number} onChange={(e)=>setvehicledata({...vehicledata,reg_number:e.target.value})} className="form-control"></input>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Initial mileage(at the time of leasing a vehicle) <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" id="initial_mileage" name="initial_mileage" value={vehicledata.initial_mileage} disabled onChange={(e)=>setvehicledata({...vehicledata,initial_mileage:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Current mileage(mileage for each month) <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" id="mileage" name="mileage" value={vehicledata.mileage} onChange={(e)=>setvehicledata({...vehicledata,mileage:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Plan Name<span style={{ color: 'red' }}>*</span></label>
                                                        <select id="purchase_type" name="purchase_type" value={vehicledata.plan} onChange={(e)=>setvehicledata({...vehicledata,plan:e.target.value})}   className="form-control " data-live-search="true">
                                                            <option value>Select option</option>
                                                            <option value="Limited Plan" >Limited Plan</option>
                                                            <option value="Unlimited Plan">Unlimited Plan</option>
                                                            <option value="Flexi Plan">Flexi Plan</option>
                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">Rental/Lease/Replacement</label>
                                                        {/* <select id="purchase_type" name="purchase_type" onChange={(e)=>setvehicledata({...vehicledata,purchase_type:e.target.value})} className="form-control " data-live-search="true"> */}
                                                        <select id="purchase_type" name="purchase_type" onChange={(e) => getRLR(e.target.value)}   className="form-control " data-live-search="true">
                                                            <option value>Select option</option>
                                                            <option value="Rental" selected={'Rental'===vehicledata.purchase_type?true:false}>Rental</option>
                                                            <option value="Lease" selected={'Lease'===vehicledata.purchase_type?true:false}>Lease</option>
                                                            <option value="Replacement" selected={'Replacement'===vehicledata.purchase_type?true:false}>Replacement</option>
                                                            <option value="Timexpress Own" selected={'Timexpress Own'===vehicledata.purchase_type?true:false}>Timexpress Own</option>
                                                            <option value="Private" selected={'Private'===vehicledata.purchase_type?true:false}>Private</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">Purchase Company</label>
                                                        <select id="purchase_company" name="purchase_company" onChange={(e)=>setvehicledata({...vehicledata,purchase_company:e.target.value})} className="form-control " data-live-search="true">
                                                            <option value="" selected={vehicledata.purchase_company===''?true:false}>Select Company</option>
                                                            <option value="National Rent A Car" selected={'National Rent A Car'===vehicledata.purchase_company?true:false}>National rent a car</option>
                                                            <option value="Lease Plan Rent A Car" selected={'Lease Plan Rent A Car'===vehicledata.purchase_company?true:false}>Lease plan rent a car</option>
                                                            <option value="Dollar Rent A Car" selected={'Dollar Rent A Car'===vehicledata.purchase_company?true:false}>Dollar rent a car</option>
                                                            <option value="Shift Car Rental" selected={'Shift Car Rental'===vehicledata.purchase_company?true:false}>Shift car rental</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`row ${rentalclass}`} style={{display: 'none'}}>                                                
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">Rental From</label>
                                                        <input type="date" id="rental_from" name="rental_from" value={vehicledata.rental_from} onChange={(e)=>setvehicledata({...vehicledata,rental_from:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">Rental to</label>
                                                        <input type="date" id="rental_to" name="rental_to" value={vehicledata.rental_to} onChange={(e)=>setvehicledata({...vehicledata,rental_to:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>                                             

                                            <div className={`row ${leaseclass}`} style={{display: 'none'}}>                                                
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">Lease From</label>
                                                        <input type="date" id="lease_from" name="lease_from" value={vehicledata.lease_from} onChange={(e)=>setvehicledata({...vehicledata,lease_from:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">Lease to</label>
                                                        <input type="date" id="lease_to" name="lease_to" value={vehicledata.lease_to} onChange={(e)=>setvehicledata({...vehicledata,lease_to:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>                                         

                                            <div className={`row ${otherclass}`} style={{display: 'none'}}>                                                
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">From</label>
                                                        <input type="date" id="lease_from" name="lease_from" value={vehicledata.replace_from} onChange={(e)=>setvehicledata({...vehicledata,lease_from:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating datefiled">to</label>
                                                        <input type="date" id="lease_to" name="lease_to" value={vehicledata.replace_to} onChange={(e)=>setvehicledata({...vehicledata,lease_to:e.target.value})} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label className="bmd-label-floating">Special Notes</label>
                                                            <textarea className="form-control" rows={2} name="notes" value={vehicledata.notes} onChange={(e)=>setvehicledata({...vehicledata,notes:e.target.value})}defaultValue={""} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">                                                
                                              <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Status</label>
                                                        <select id="status" name="status" onChange={(e)=>setvehicledata({...vehicledata,status:e.target.value})} className="form-control " data-live-search="true">
                                                            <option value="" selected={vehicledata.status===''?true:false}>Choose Vehicle Gear</option>
                                                            <option value="1" selected={'1'===vehicledata.status?true:false}>Active</option>
                                                            <option value="2" selected={'2'===vehicledata.status?true:false}>Not Active</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12" style={{textAlign: 'right'}}>
                                                <p style={{ color: msgcolor }}>{veh_type_err}</p>
                                                    <a className="btn btn-primary pull-right" onClick={submit_vehicle} style={{color: '#fff'}}>Update Vehicle</a>
                                                </div>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditVehicle;