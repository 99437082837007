import SidebarMenu from "../SidebarMenu";
import PodModal from "./PodModal";

const RetrievePod = () => {
    return(        
        <div className="wrapper-holder">
            <SidebarMenu/>
            
            <div className="main-panel ps-container">                
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <PodModal/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RetrievePod;