import React from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

export default function Logout() {
    
    const location = useLocation();  
    const navigate=useNavigate()
    React.useEffect(() => {
        sessionStorage.removeItem('userid');
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('branch');
        // window.location.replace(`${location.state.payment_page}`)
        navigate('/')
      }, [])

  return (
    <div>
    
     
    </div>
  )
}
