import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import loading from '../../images/spin.gif'
import Modal from 'react-modal';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import '../../Form.css'
import Checklogin from './Checklogin';
import { Link, useNavigate } from 'react-router-dom';

export default function Driver_Vehicles() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  
  const API_PATH = `${SERVER_URL}get_driver_vehicles.php`;
  const [drivers, setdrivers] = useState()
  const [isload, setisload] = useState(true)
  const [data, setdata2] = useState([])

  const url=window.location.href
  const driverid = url.split("/").pop();

  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: driverid
    }).then((res) => {
      console.log(res.data)
      
      setdrivers(res.data)
      setdata2(res.data)
      setisload(false)
    })
      .catch((err) => console.log(err))

  }, [])




  let today = new Date();
  let dd = today.getDate();
  
  let mm = today.getMonth()+1; 
  let yyyy = today.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 
  
  if(mm<10) 
  {
      mm='0'+mm;
  } 
  today = yyyy+'-'+mm+'-'+dd;
  const [todaydate, settodaydate] = useState(today)

  const columns = [
       
    {
      name: <div className="datahead">Driver</div>,
      selector: "driver_name",
      sortable: true
    },
    {
        name: <div className="datahead">Plate Number</div>,
        selector: "plate_number",
        sortable: true
      },
      {
        name: <div className="datahead">Reg.Number</div>,
        selector: "reg_number",
        sortable: true
      }
      ,
      {
        name: <div className="datahead">Branch</div>,
        selector: "branch",
        sortable: true
      }
      ,
      {
        name: <div className="datahead">From Date</div>,
        selector: "from_date",
        sortable: true
      },
      {
        name: <div className="datahead">To Date</div>,
        selector: "to_date",
        sortable: true
      }
      ,
      {
        name: <div className="datahead">Status</div>,
        selector: "status",
        sortable: true
      }
      
  
  ];
  const tableData = {
    columns,
    data
  };

 
  



  return (
    
    <div className='wrapper-holder driver'>
    
            
      {<SidebarMenu />}

  
  
      <div className="main-panel ps-container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
             
 
                <div className="card">
                  <div className="card-header card-header-primary">
                    <h4 className="card-title">Driver vs Vehicles</h4>
                    <p className="card-category"></p>
              
      
                  </div>
                  <div className="card-body">
                  {isload?(
  <div style={{textAlign:"center"}}>
  <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>

  </div>
):(<DataTableExtensions {...tableData}>
  <DataTable
    columns={columns}
    data={data}
    noHeader
    defaultSortField="id"
    // sortIcon={<SortIcon />}
    defaultSortAsc={true}
    pagination
    highlightOnHover
    dense
  />
</DataTableExtensions>
)}

                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
