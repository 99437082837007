import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import loading from '../../images/spin.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar,faClose,faCalendarDays,faAddressCard,faPenToSquare, faBatteryEmpty} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import '../../Form.css'
import Checklogin from './Checklogin';
import { Link, useNavigate } from 'react-router-dom';

const deleteicon = <FontAwesomeIcon icon={faClose}  style={{color:'red'}}/>
const editicon = <FontAwesomeIcon icon={faPenToSquare} style={{color:'blue'}}/>
export default function VehicleTypeList() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const navigate=useNavigate()

  const [isload, setisload] = useState(true)
  const [showload, setshowload] = useState(false)
  const [data, setdata2] = useState([])
  
  const API_PATH=`${SERVER_URL}vehicle_type.php`
  useEffect(()=>{
    axios({
        method:'post',
        url:API_PATH,
        data:{user:user,type:'list'}
    })
    .then((res)=>{
      console.log(res.data)
      setdata2(res.data)
      setisload(false)
    })
    .catch((err)=>console.log(err.message))

  },[])

  const columns = [
       
    {
      name: <div className="datahead">Vehicle Type</div>,
      cell: (row) =>row.vehicle_type,
      sortable: true
    },
    {
        name: <div className="datahead">Fuel Allowance</div>,
        cell: (row) =>row.month_allowance,
        sortable: true
      },
      {
        name: <div className="datahead">KM Allowance</div>,
        cell: (row) =>row.km_allowance,
        sortable: true
      }
      
      ,
      {
        name:"Action",
        cell: (row) => <div className="btn-group" role="group" aria-label="Basic example">
        <a  id={row.id} onClick={()=>deletetype(row.id)} className="">{deleteicon}</a>&nbsp; &nbsp;<a  id={row.id}  onClick={()=>editVehicleType(row.id)} className="">{editicon}</a>&nbsp;&nbsp;
  
       </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false
      },
      ,
      
  ];
  const tableData = {
    columns,
    data
  };

 
 
  function deletetype(val){
           
    window.confirm('Are you sure you wish to delete this item?') ? deletethistype(val) : deletethistype(0)

}

const [msg, setmsg] = useState()


function deletethistype(val){
    if(val>0){
       
   const API_PATH=`${SERVER_URL}vehicle_type.php`;
    axios({
        method: 'post',
        url: `${API_PATH}`,
        data: {user:user,record:val,type:'delete'}
    })
    .then((res)=>{
        console.log(res.data)
        // navigate('/my-account/address-book')
       window.location.reload();

    })
    .catch(err=>console.log(err))
   
}
}

function editVehicleType(val){
  navigate(`/edit-vehicle-type/${val}`)
}




  return (
    
    <div className='wrapper-holder driver'>
      {<SidebarMenu />}
      <div className="main-panel ps-container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-primary">
                    <h4 className="card-title">Vehicle Type List</h4>
                    <p className="card-category"></p>
              
      
                  </div>
                  <div className="card-body">
                  {isload?(
  <div style={{textAlign:"center"}}>
  <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>

  </div>
):(<DataTableExtensions {...tableData}>
  <DataTable
    columns={columns}
    data={data}
    noHeader
    defaultSortField="id"
    // sortIcon={<SortIcon />}
    defaultSortAsc={true}
    pagination
    highlightOnHover
    dense
  />
</DataTableExtensions>
)}

   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
