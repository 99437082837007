import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import loading from '../../images/spin.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faClose, faCalendarDays, faAddressCard, faPenToSquare, faBatteryEmpty } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import '../../Form.css'
import Checklogin from './Checklogin';
import { Link, useNavigate } from 'react-router-dom';
const car = <FontAwesomeIcon icon={faCar} style={{ color: 'blue' }} />
const deleteicon = <FontAwesomeIcon icon={faClose} style={{ color: 'red' }} />
const calender = <FontAwesomeIcon icon={faCalendarDays} style={{ color: 'green' }} />
const addresscard = <FontAwesomeIcon icon={faAddressCard} style={{ color: 'purple' }} />
const editicon = <FontAwesomeIcon icon={faPenToSquare} style={{ color: 'blue' }} />
export default function VisaApplications() {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const branch = sessionStorage.getItem('branch')
    const role = sessionStorage.getItem('role')
    const user = sessionStorage.getItem('userid')
    const navigate = useNavigate()
    const API_PATH = `${SERVER_URL}visa_applications.php`;
    const [drivers, setdrivers] = useState()
    const [isload, setisload] = useState(true)
    const [showload, setshowload] = useState(false)
    const [data, setdata2] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    useEffect(() => {
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: { branch: branch, user_role: role, status: 1, type: 'normal' }
        }).then((res) => {
            console.log(res.data)

            setdrivers(res.data)
            setdata2(res.data)
            setisload(false)
        })
            .catch((err) => console.log(err))

    }, [])

    function viewRow() {

    }


    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    const [todaydate, settodaydate] = useState(today)

    const columns = [

        {
            name: <div className="datahead">Full name</div>,
            selector: "driver_name",
            sortable: true
        },
        {
            name: <div className="datahead">Branch</div>,
            selector: "branch",

            sortable: true
        },
        {
            name: <div className="datahead">Mobile</div>,
            selector: "phone",
            sortable: true
        }
        ,
        {
            name: <div className="datahead">Date Employed</div>,
            selector: "date_employed",
            sortable: true
        },
        {
            name: <div className="datahead">Applied For</div>,
            selector: "applied_for",
            sortable: true
        }
        ,
        {
            name: <div className="datahead">Visa Request</div>,
            cell: (row) => <div>
                {row.approval_status == 0 ? (
                    <p style={{ textAlign: 'center' }}>Pending</p>
                ) : null}

            </div>,
        }
        ,

        {
            name: "Action",
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic example">

                <a id={row.id} onClick={() => setModalIsOpenToTrue2(row.driver_id)} className="">{addresscard}</a>
            </div>,
            ignoreRowClick: true,
            allowOverflow: true,
            selector: false
        }

    ];
    const tableData = {
        columns,
        data
    };



    const API_PATH1 = `${SERVER_URL}get_driver_details.php`;
    const [vacationdata, setvacationdata] = useState({
        leave_from: '',
        leave_to: '',
        notes: '',
        leave_type: '',
        driver_id: '',
        leave_form: ''
    })
    const [joindata, setjoindata] = useState({
        driver_name: '',
        change_date: '',
        date_employed: ''
    })


    const [msg, setmsg] = useState()

    const [visadata, setvisadata] = useState({
        driver_name: '',
        visa_type: '',
        date_employed: '',
        branch: '',
        passport_number: '',
        phone: '',
        notes: ''
    })
    const [driver, setdriver] = useState()
    const API_VISA = `${SERVER_URL}get_driver_visa_details.php`
    function setModalIsOpenToTrue2(val) {
        setdriver(val)
        axios({
            method: 'post',
            url: `${API_VISA}`,
            data: val
        }).then((res) => {
            setmsg('')
            console.log('here ',res.data.visadetails)
            setvisadata(res.data.visadetails)
            setModalIsOpen2(true)

        }).catch(err => console.log(err.message))
    }

    console.log(visadata)
    function setModalIsOpenToFalse2() {
        setModalIsOpen2(false)
        window.location.reload();
    }


    const Apply_Visa = `${SERVER_URL}visa_applications.php`
    function apply_visa(event) {
        event.preventDefault();

        if (visadata.applied_for != '' && visadata.applied_for != null) {
            
            setshowload(true)
            axios({
                method: 'post',
                url: `${Apply_Visa}`,
                data: { user: user, approval_status: visadata.approval_status, driver: driver, type: 'update_visa' }
            }).then((res) => {console.log(res.data)
                setmsg(res.data.msg)
                setshowload(false)
            }).catch(err => console.log(err.message))

        } else {
            setmsg('Please enter all the required field')
        }
    }


    return (

        <div className='wrapper-holder driver'>


            {<SidebarMenu />}



            <div className="main-panel ps-container">
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">


                                {/* visa application  */}

                                <Modal isOpen={modalIsOpen2} ariaHideApp={false}>

                                    <>


                                        <div className="modal-header">
                                            <h4 className="modal-title">Apply For Time Express Visa </h4>
                                            <button type="button" onClick={setModalIsOpenToFalse2} className="close" data-dismiss="modal">×</button>
                                        </div>
                                        {/* Modal body */}
                                        {/* <div className="modal-body"> */}
                                        <div className="container-fluid modalcontainer">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <form id="myform2" encType="multipart/form-data">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label className htmlFor="driver">Driver</label>
                                                                            <input type="text" id="drivervisa" name="drivervisa" defaultValue={visadata.driver_name} className="form-control" disabled />
                                                                            <input type="hidden" id="driver_idvisa" name="driver_idvisa" className="form-control" defaultValue />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" >
                                                                            <label className="bmd-label-static" htmlFor="driver">Visa Application<span style={{ color: 'red' }}> * </span></label>
                                                                            <select id="visa_apply_type" name="visa_apply_type" defaultValue={visadata.applied_for} onChange={e => setvisadata({ ...visadata, applied_for: e.target.value })} className="form-control">
                                                                                <option value>Select Visa Application</option>
                                                                                <option value="Time Express Visa">Time Express Visa</option>
                                                                                <option value="Time Express Visa Renewal">Time Express Visa Renewal</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" style={{ marginTop: '13px' }}>
                                                                            <label>Joined Date</label>
                                                                            <input type="date" className="start form-control" defaultValue={visadata.date_employed} id="joined_date2" name="joined_date2" disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" style={{ marginTop: '13px' }}>
                                                                            <label>Branch</label>
                                                                            <input type="text" className="start form-control" id="branch" defaultValue={visadata.branch} name="branch" disabled />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" style={{ marginTop: '13px' }}>
                                                                            <label>Current Visa Type</label>
                                                                            <input type="text" className="start form-control" id="visa_type" defaultValue={visadata.visa_type} name="visa_type" disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" style={{ marginTop: '13px' }}>
                                                                            <label>Passport Number</label>
                                                                            <input type="text" className="start form-control" id="passport_no" defaultValue={visadata.passport_number} name="passport_no" disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" style={{ marginTop: '13px' }}>
                                                                            <label>Phone</label>
                                                                            <input type="text" className="start form-control" id="phone" defaultValue={visadata.phone} name="phone" disabled />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" style={{ marginTop: '13px' }}>
                                                                            <label>Special notes</label>
                                                                            <textarea rows={2} className="start form-control" id="visanote" defaultValue={visadata.notes} onChange={e => setvisadata({ ...visadata, notes: e.target.value })} name="visanote" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label>Approval Status </label>
                                                                        <div className="form-group">
                                                                            <select id="leave_type" name="leave_type" onChange={(e) => setvisadata({ ...visadata, approval_status: e.target.value })} defaultValue={visadata.approval_status && visadata.approval_status != '' ? visadata.approval_status : ''} className="form-control">
                                                                                <option>Select Status</option>
                                                                                <option value="0">Pending</option>
                                                                                <option value="1">Approved</option>
                                                                                <option value="2">Rejected</option>

                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                </div>
                                                                <p id="signvisa" />
                                                                <a className="btn btn-primary pull-right" onClick={e => apply_visa(e)} style={{ color: '#fff !important' }} id="addusr">Apply Visa</a>
                                                                <div className="clearfix" />
                                                                {showload ? (
                                                                    <img src={loading} style={{ width: '5%', marginLeft: '11%' }} />
                                                                ) : null}
                                                                <p>{msg}</p>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                        {/* Modal footer */}
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary closebtn" onClick={setModalIsOpenToFalse2} >Close</button>

                                        </div>
                                    </>
                                </Modal>


                                <div className="card">
                                    <div className="card-header card-header-primary">
                                        <h4 className="card-title">Visa Application List</h4>
                                        <p className="card-category"></p>


                                    </div>
                                    <div className="card-body">
                                        {isload ? (
                                            <div style={{ textAlign: "center" }}>
                                                <p style={{ textAlign: "center" }}>Please wait. The data is loading ! </p>

                                            </div>
                                        ) : (<DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                // sortIcon={<SortIcon />}
                                                defaultSortAsc={true}
                                                pagination
                                                highlightOnHover
                                                dense
                                            />
                                        </DataTableExtensions>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
