import SidebarMenu from "../SidebarMenu";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Select from 'react-select';

const UserEdit = () => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const role = sessionStorage.getItem('role');
    const user = sessionStorage.getItem('userid');    
    const navigate=useNavigate();
    const url=window.location.href;
    const getuser_id = url.split("/").pop();


    useEffect(() => {
        getEditUser(getuser_id);
        getBranchlist();
        getSelectBranch(getuser_id);
    }, []);

    const getEditUser = async ($type = "") => {
        const API_PATH = `${SERVER_URL}user.php`;
        const passedituser_id = {
            type: "edituser",
            user_id: $type.toString()
        };
        await axios
            .post(API_PATH, passedituser_id)
            .then(res => {
                // console.log(res);
                const editdata = res.data.find((data) => data.id.toString() == getuser_id);
                const splitbranch = editdata.branch.split(",");
                setedituser({...edituser,
                    name: editdata.user,
                    username: editdata.username,
                    password: '******',
                    password1: editdata.password,
                    email: editdata.email,
                    phone: editdata.phone,
                    // branch: editdata.branch
                    branch: splitbranch
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }
    

    const [listBranch, setlistBranch] = useState();
    const getBranchlist = async () => {
        const API_PATH = `${SERVER_URL}user.php`;
        const passval = {
          type: "branch-list",
          user: user
        };    
        await axios
          .post(API_PATH, passval)
          .then(res => {
            // console.log(res);
            setlistBranch(res.data);
    
          })
          .catch((err) => {
            console.log(err);
          })
    }  

    const [seletedB, setSeletedB] = useState();
    const getSelectBranch = async ($type = "") => {
        const API_PATH = `${SERVER_URL}user.php`;
        const passval = {
          type: "selected-branch",
          user_id: $type.toString(),
          user: user
        };
        await axios
            .post(API_PATH,passval)
            .then(res => {
                // console.log(res);
                setSeletedB(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const selected = seletedB?.map(data => ({
        label: `${data.branch}`,
        value: `${data.id}`
    }))

    const options = listBranch?.map(data => ({
        label: `${data.branch}`,
        value: `${data.id}`
    }))

    const defaultValues = {
        name: '',
        username: '',
        password: '',
        password1: '',
        email: '',
        phone: '',
        branch: ''
    }

    const seleted = [
        { value: '1', label: 'DHL DUBAI' },
        { value: '7', label: 'DSV NOKIA' }
    ]

    var selectMultiChange = (e) => {
        // setselectbranch(Array.isArray(e)?e.map(data=>data.value):[]);
        setedituser({...edituser,branch:Array.isArray(e)?e.map(data=>data.value):[]});
    }

    const [edituser, setedituser] = useState(defaultValues);
    const [err_msg, set_err_msg] = useState();
    const update_user = async () => {
        const API_PATH = `${SERVER_URL}user.php`;
        console.log(edituser);
        let countError = 0, fieldLoop = edituser;
        // let lastAtPos = edituser.email.lastIndexOf("@");
        // let lastDotPos = edituser.email.lastIndexOf(".");
        // Object.entries(fieldLoop).map( ( [key, value] ) => {
        //   if ( value==='' ){
        //     countError++;
        //     set_err_msg("Please enter all the required field!");
        //     return false; // stop here...     
        //   }  
        // });

        // if (!(
        //   lastAtPos < lastDotPos &&
        //   lastAtPos > 0 &&
        //   edituser.email.indexOf("@@") == -1 &&
        //   lastDotPos > 2 &&
        //   edituser.email.length - lastDotPos > 2
        // )){
        //   set_err_msg('Please enter a valid email id');
        //   countError++;
        //   return false; // stop here... 
        // }

        if(countError > 0){
          return false; // stop here...
        }else{
          const passval = {
            type: 'updateuser',
            name: edituser.name,
            username: edituser.username,
            password: edituser.password,
            password1: edituser.password1,
            email: edituser.email,
            phone: edituser.phone,
            branch: edituser.branch,
            user_id: getuser_id,
            user: user
          };
        //   console.log(passval);
          await axios
            .post(API_PATH, passval)
            .then(res =>{
              console.log(res);
              if(res.data.status == '1'){
                set_err_msg(res.data.message);  
                  setTimeout(()=> {
                    //   window.location.reload(); 
                    navigate('/user-list'); 
                  }, 2000);  
              }else{
                set_err_msg(res.data.message); 
              }   
            })
            .catch((err) => {
              console.log(err);
            })
        }
    }

    return(
        <div className='wrapper-holder'>
            {<SidebarMenu />}
            
            <div className="main-panel ps-container">
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-primary">
                                        <h4 className="card-title">Update User</h4>
                                        {/* <p className="card-category">Complete your profile</p> */}
                                    </div>

                                    
                                    <div className="card-body">
                                        <form id="myform" encType="multipart/form-data">                                            
                                            <div className="row">

                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Name <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text"  id="name" name="name" value={edituser.name} onChange={(e)=>setedituser({...edituser,name:e.target.value})} className="form-control"></input>
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>                          
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Username <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text"  id="username" name="username" value={edituser.username} onChange={(e)=>setedituser({...edituser,username:e.target.value})} className="form-control"></input>
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>                          
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Password <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text"  id="password" name="password" value={edituser.password} onChange={(e)=>setedituser({...edituser,password:e.target.value})} className="form-control"></input>
                                                        <input type="text"  id="password1" name="password1" value={edituser.password1} onChange={(e)=>setedituser({...edituser,password1:e.target.value})} className="form-control"></input>
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>                         
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Email <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="email"  id="email" name="email" value={edituser.email} onChange={(e)=>setedituser({...edituser,email:e.target.value})} className="form-control"></input>
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>                         
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Phone <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="text"  id="phone" name="phone" value={edituser.phone} onChange={(e)=>setedituser({...edituser,phone:e.target.value})} className="form-control"></input>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating" style={{ marginTop: '5px', width: '100%', textAlign: 'left' }}>Branch <span style={{ color: 'red' }}>*</span></label>
                                                        
                                                        {
                                                            seletedB && seletedB != ""?(
                                                                <>
                                                                    <Select
                                                                        isMulti
                                                                        onChange={selectMultiChange}
                                                                        defaultValue={selected}
                                                                        options={options}
                                                                    />  
                                                                </>
                                                            ):
                                                            <Select
                                                                isMulti
                                                                onChange={selectMultiChange}
                                                                options={options}
                                                            />  
                                                        }
                                                               

                                                        {/* <select id="branch" name="branch" onChange={(e)=>setedituser({...edituser,branch:e.target.value})} className="form-control " data-live-search="true"> */}
                                                        {/* <select id="branch" name="branch" onChange={(e)=>getBranchId(e.target.value)} className="form-control " data-live-search="true">                                   
                                                            <option value="" selected={edituser.branch===''?true:false}>Choose Branch</option>
                                                            {
                                                            listBranch && listBranch !=""?(
                                                                <>
                                                                {
                                                                    listBranch.map(data => (
                                                                    <option value={data.id}>{data.branch}</option>
                                                                    ))
                                                                }
                                                                </>
                                                            ):null
                                                            }
                                                        </select> */}
                                                    </div>
                                                </div>

                                                <div className="col-md-12" style={{textAlign: 'right'}}>
                                                    <p style={{ color: "red" }}>{err_msg}</p>
                                                    <a className="btn btn-primary pull-right" onClick={update_user} style={{color: '#fff'}}>Update User</a>
                                                </div>
                                            
                                            </div>                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserEdit;