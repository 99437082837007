import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import '../../Form.css'
import Select from 'react-select';
import loading from '../../images/spin.gif';
import logo from "../../images/timex.png"
import leave_form_file from "../../images/leave_form.pdf"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileDownload} from '@fortawesome/free-solid-svg-icons'
const download = <FontAwesomeIcon icon={faFileDownload} />
export default function LeaveFormLink() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const SERVER_PATH = process.env.REACT_APP_SERVER_PATH;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = 0
  const url=window.location.href
  const driverid = url.split("/").pop();
  
  const navigate=useNavigate()

 
  const [isload, setisload] = useState(false)

  const [msg, setmsg] = useState()

  const API_PATH1=`${SERVER_URL}get_driver_details.php`;
const [vacationdata, setvacationdata] = useState({
  leave_from:'',
  leave_to:'',
  notes:'',
  leave_type:'',
  driver_id:'',
  leave_form:''
})
const [joindata, setjoindata] = useState({
  driver_name:'',
  change_date:'',
  date_employed:''
})

const [leaveformview, setleaveformview] = useState()
const [settlementformview, setsettlementformview] = useState()
useEffect(() => {
    axios({
        method:'post',
         url:`${API_PATH1}`,
         data:driverid
      }).then((res)=>{
       
       if(res.data.vacationdetails!=null){
        setvacationdata(res.data.vacationdetails)
        if(res.data.vacationdetails.leave_form!=='' && !!res.data.vacationdetails.leave_form){
            const leaveformpath=res.data.vacationdetails.leave_form.split('uploads/')
            
            setleaveformview(`${SERVER_PATH}/uploads/${leaveformpath[1]}`)
          }
    
          if(res.data.vacationdetails.settlement_form!=='' && !!res.data.vacationdetails.settlement_form){
            const settlement_formpath=res.data.vacationdetails.settlement_form.split('uploads/')
            
            setsettlementformview(`${SERVER_PATH}/uploads/${settlement_formpath[1]}`)
          }
       
       }else{
        setvacationdata({...vacationdata, leave_from:'',
        leave_to:'',
        notes:'',
        leave_type:'',
        driver_id:'',
        leave_form:''
      })
    
       }
       if(res.data.joindetails!=null){
        setjoindata(res.data.joindetails)
       }
       setmsg('')
   
        
      }).catch(err=>console.log(err.message))
}, [])

const [image_info, setimage_info] = useState({
    filedetails:{
     settlement:'',
     leave_form:''
    }
  }) 

function updatefiles(val, i,k) {
 
    const clonedListing = { ...image_info.filedetails };
  
  if(k=='settlement'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      settlement: val
    }
  });
  }else if(k=='leave_form'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      leave_form: val
    }
  });
  }
  }
 
  const [showload, setshowload] = useState(false)
  const [msgcolor, setmsgcolor] = useState('green')
  const API_UpdateLeave=`${SERVER_URL}user_actions.php`
 function apply_leave(event){
  event.preventDefault();
if(vacationdata && (vacationdata.leave_form || image_info.filedetails.leave_form)){
  setmsg("")
  setshowload(true)
  axios({
    method:'post',
    url:API_UpdateLeave,
    data:{user:user,vacationdata:vacationdata,joindata:joindata,type:'updateleave'}
  }).then((res)=>{
    console.log(res.data)
    if(res.data>1){ 
      saveimages(event, res.data)
    }
  }).catch((err)=>console.log(err.message))
}else{
  setmsgcolor('red')
  setmsg("Please enter all the required fields")
}
 }

 function saveimages(event, vacationid) {
    console.log(image_info.filedetails);
        event.preventDefault();
        const data = new FormData();
        data.append("leave_form[]", image_info.filedetails.leave_form);
        data.append("vacationid", vacationid)
        data.append("passportno",joindata.passport_number)
        data.append("leaveform2",vacationdata.leave_form)
    
        let url = `${SERVER_URL}vacation_fileupload.php`;
    
        axios.post(url, data, {
          // receive two parameter endpoint url ,form data
        })
          .then((res) => {
            console.log('heree',res.data)
            setshowload(false)
            setmsgcolor('green')
            setmsg(res.data.msg)
        
           // navigate('/drivers')
            // then print response status
          
          }, error => {
            alert(error);
          
    
          });
    
    
      }

  return (
    <div className='wrapper-holder' style={{background:'#cdd3d6'}}>
      {/* {<SidebarMenu />} */}
      <div className="container">
     
        <div className="formclass">
            <div className="row formheadlink">
                <div className="col-md-4">
                <img src={logo} style={{width:'50%',float:'left'}}/>
                </div>
                <div className="col-md-4">
                <h3 style={{marginTop:'0px',fontFamily: 'fantasy',color:'#7f3f98'}}>Leave Form</h3>
      <p style={{lineHeight:'0px'}}>Please fill all the fields</p>
                </div>
                <div className="col-md-4">
                    <a href={leave_form_file} download="leave_form" target="_blank" style={{color:'#7f3f98',fontWeight:'bold',float:'right'}}>Download Leave Form {download}</a>
                    
                </div>
            </div>
          
            <form id="myform" encType="multipart/form-data">
                        <div className="row">
                          <div className="col-md-6">
                        
                            <div className="form-group" style={{marginTop: '13px'}}>
                            <label style={{maxHeight:'0px',marginTop:'-15px'}}>Driver</label>
                              <input type="text" id="driver" name="driver" defaultValue={joindata.driver_name}  className="form-control" disabled />
                              <input type="hidden" id="driver_id" name="driver_id" className="form-control" disabled defaultValue />
                            </div>            
                          </div>
                          <div className="col-md-6">
                          <label>Leave Type</label>
                            <div className="form-group">
                              <select id="leave_type" name="leave_type" onChange={(e)=>setvacationdata({...vacationdata,leave_type:e.target.value})} defaultValue={vacationdata.leave_type && vacationdata.leave_type!=''?vacationdata.leave_type:''} className="form-control">
                                <option>Select Leave Type</option>
                                <option value="Sick Leave">Sick Leave</option>
                                <option value="Emergency Leave">Emergency Leave</option>
                                <option value="Annual Leave">Annual Leave</option>
                                <option value="Local Leave">Local Leave</option>
                                <option value="Maternity Leave">Maternity Leave</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Leave From <span style={{color: 'red'}}> * </span></label>
                              <input type="date" className="start form-control" onChange={(e)=>setvacationdata({...vacationdata,leave_from:e.target.value})}  id="start" name="start" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Leave To <span style={{color: 'red'}}> * </span></label>
                              <input type="date" className="end form-control" id="end" onChange={(e)=>setvacationdata({...vacationdata,leave_to:e.target.value})}  name="end"  />
                            </div>
                          </div>
                        </div> 
                        {/* <div className="row">
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Joined Date</label>
                              <input type="date" className="start form-control" id="joined_date" defaultValue={joindata.date_employed} name="joined_date" disabled />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Change Status Date </label>
                              <input type="date" className="end form-control" id="change_date" defaultValue={joindata.change_date} name="change_date"  disabled />
                            </div>
                          </div>
                        </div>  */}
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Special Notes</label>
                              <div className="form-group">
                                <label className="bmd-label-floating"> </label>
                                <textarea className="form-control" rows={3} onChange={(e)=>setvacationdata({...vacationdata,notes:e.target.value})} name="notes"  id="notes" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label  style={{width:'55%'}}>Leave Form <span style={{color: 'red'}}> * </span>
                              <div className="custom-file">
                              <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'leave_form')} />
                           
                              </div>
                            </label>
                            <input type="hidden" name="leave_form1" id="leave_form1" />  
                            <div className="view_form">			   
                            </div>
                            {/* {
                          vacationdata.leave_form && vacationdata.leave_form!=''?(
                             <a href={leaveformview} className="viewform" target="_blank" style={{marginTop:'7%'}}>View Form</a>
                    
                          ):null
                        }		    */}
                          </div>
                          {/* <div className="col-md-6">
                                  <label  style={{width:'55%'}}>Settlement Form 
                                    <div className="custom-file">
                                      <input type="file" style={{ float: 'right' }} onChange={(e) => updatefiles(e.target.files[0], 1, 'settlement')}  disabled/>

                                    </div>
                                  </label>
                                  <input type="hidden" name="leave_form1" id="leave_form1" />
                                  <div className="view_form">
                                  </div>
                                  {
                                vacationdata.settlement_form && vacationdata.settlement_form != '' ? (
                                  <a href={settlementformview} target="_blank" style={{color:'#7f3e98 !important',fontWeight:'bold',marginTop:'7%'}}>View Form</a>

                                ) : null
                              }
                                </div> */}
                        </div>
                       
                        <p id="sign" />
                        <a className="btn btn-primary pull-right" onClick={e=>apply_leave(e)} style={{color: '#ffffff !important'}}>Apply Leave</a>
                        <div className="clearfix" />
                        {showload?(
                          <img src={loading} style={{width:'5%',marginLeft:'11%'}}/>
                        ):null}
                        <p style={{color:msgcolor}}>{msg}</p>
                      </form>
       
        </div>
        <div className="row">
            
        </div>
      </div>
    
    
    </div>
  )
}
