import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import '../../Form.css'
import loading from '../../images/spin.gif';
import { useNavigate } from 'react-router-dom';

export default function EditStaff() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const SERVER_PATH = process.env.REACT_APP_SERVER_PATH;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const navigate=useNavigate()
  const API_PATH = `${SERVER_URL}staff.php`;
  const [branches, setbranches] = useState()
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { branch: branch, user_role: role ,type:'branch'}
    }).then((res) => {
      console.log(res.data)
      setbranches(res.data)
    })
      .catch((err) => console.log(err))

  }, [])


  const [staffdata, setstaffdata] = useState({
    branch:'',
    designation:'',
    first_name:'',
    last_name:'',
    email:'',
    employee_number:'',
    mobile_number:'',
    phone:'',
    emp_date:'',
    citizenship:'',
    passport_number:'',
    pdrp_expiry_date:'',
    license_type:'',
    license_expiry:'',
    license_expiry_date:'',
    change_date:'',
    work_permit_number:'',
    work_permit_expiry:'',
    dob:'',
    emirates_id_no:'',
    license_no:'',
    visa_type:'',
    own_vehicle:'',
    home_contact:'',
    home_contact_no:'',
    res_address:'',
    res_city:'',
    notes:'',
    passport_doc:'',
    date_employed:''

  })

  const url=window.location.href
  const staffid = url.split("/").pop();


  const [displaypic, setdisplaypic] = useState('')
  const [displaylicense, setlicense] = useState('')
  const [displaypassport, setpassport] = useState('')
  const [displayvisa, setvisa] = useState('')
  const [displayeid, seteid] = useState('')
  const [displaylaborcontract, setlaborcontract] = useState('')
  const [displayotherdoc, setotherdoc] = useState('')

  const API_STAFF = `${SERVER_URL}basic_details.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_STAFF}`,
      data:{staffid:staffid,type:'staff'}
    }).then((res) => {
     // console.log(res.data)
      setstaffdata(res.data)
      if(res.data.photo!=='' && !!res.data.photo){console.log('photo ',res.data.photo)
        const photopath=res.data.photo.split('uploads/')
        
        setdisplaypic(`${SERVER_PATH}/uploads/${photopath[1]}`)
      }
      
      if(res.data.driving_license!='' && !!res.data.driving_license){
        const licensepath=res.data.driving_license.split('uploads/')
       setlicense(`${SERVER_PATH}/uploads/${licensepath[1]}`)
      }
      if(res.data.passport!='' && !!res.data.passport){
        const passportpath=res.data.passport.split('uploads/')
        setpassport(`${SERVER_PATH}/uploads/${passportpath[1]}`)
      }
      if(res.data.visa!='' && !!res.data.visa){
        const visapath=res.data.visa.split('uploads/')
      setvisa(`${SERVER_PATH}/uploads/${visapath[1]}`)
      }
      if(res.data.emirates_id!='' && !!res.data.emirates_id){
        const emirates_idpath=res.data.emirates_id.split('uploads/')
        console.log('eid: ',emirates_idpath)
       seteid(`${SERVER_PATH}/uploads/${emirates_idpath[1]}`)
      }
      if(res.data.labour_contract!='' && !!res.data.labour_contract){
        const labour_contractpath=res.data.labour_contract.split('uploads/')
       setlaborcontract(`${SERVER_PATH}/uploads/${labour_contractpath[1]}`)
      }
      if(res.data.other_doc!='' && !!res.data.other_doc){
        const otherdocpath=res.data.other_doc.split('uploads/')
       setotherdoc(`${SERVER_PATH}/uploads/${otherdocpath[1]}`)
      }

    })
      .catch((err) => console.log(err))

  }, [])

  const [msg2, setmsg2] = useState()
  const [staffvacation, setstaffvacation] = useState()

  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_STAFF}`,
      data: {staffid:staffid,type:'staff_vacation'}
    }).then((res) => {
     // console.log(res.data)
     setstaffvacation(res.data)
    })
      .catch((err) => console.log(err))

  }, [])

  function closeLeave(val,val2){
   
    
    if(staffvacation.rejoindate !=''){
        axios({
          method:'post',
          url:`${API_STAFF}`,
          data:{'user':user,'rejoindate':staffvacation.rejoindate,'staffid':val,'vacationid':val2,type:'close_staff_leave'}
        }).then((res)=>{
          if(res.data==1){
            setmsg2('Leave Closed successfully')
          }else{
            setmsg2('Unable to update the details')
          }
         
        
         
        }).catch((err)=>console.log(err))
    }else{
      setmsg('Please fill all the required fields')
      setmsgcolor('red')
    }
  }
  
  const [isload, setisload] = useState(false)  
  const [msg, setmsg] = useState()
  const [msgcolor, setmsgcolor] = useState()
  const API_PATH2=`${SERVER_URL}update_staff.php`
  function submit_staff(event){
    console.log('staffid ',staffid)
    event.preventDefault();

    if(staffdata.first_name !='' && staffdata.last_name!='' && staffdata.phone!='' && staffdata.date_employed!='' && staffdata.citizenship!='' && staffdata.passport_number!='' ){
      setisload(true)
        axios({
          method:'post',
          url:`${API_PATH2}`,
          data:{'user':user,'staffdata':staffdata,'staffid':staffid}
        }).then((res)=>{
          setisload(false)
         setmsg(res.data.msg)
         setmsgcolor('green')
        saveimages(event, staffid)
        }).catch((err)=>console.log(err))
    }else{
      setisload(false)
      setmsg('Please fill all the required fields')
      setmsgcolor('red')
    }
  }

  const [image_info, setimage_info] = useState({
    filedetails:{
     photo:'',
     passport:'',
     visa:'',
     emirates_id:'',
     license:'',
     labor_contract:'',
     other:''
    }
  }) 

  function updatefiles(val, i,k) {
    //const updatedCount = 1;
    const clonedListing = { ...image_info.filedetails };
    //const updatedAccepted = [...clonedListing.image]
   // updatedAccepted[i] = val;
if(k=='photo'){
  setimage_info({
    filedetails: {
      ...clonedListing,
    photo: val
    }
  });
}else if(k=='passport'){
  setimage_info({
    filedetails: {
      ...clonedListing,
    passport: val
    }
  });
}
else if(k=='visa'){
  setimage_info({
    filedetails: {
      ...clonedListing,
    visa: val
    }
  });
}
else if(k=='emirates_id'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      emirates_id: val
    }
  });
}
else if(k=='license'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      license: val
    }
  });
}
else if(k=='labor_contract'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      labor_contract: val
    }
  });
}
else if(k=='other'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      other: val
    }
  });
}
   
   
  }


  function saveimages(event, orderid) {
console.log(image_info.filedetails);
    event.preventDefault();
    const data = new FormData();
    data.append("photo[]", image_info.filedetails.photo);
    data.append("passport[]", image_info.filedetails.passport);
    data.append("visa[]", image_info.filedetails.visa);
    data.append("emirates_id[]", image_info.filedetails.emirates_id);
    data.append("license[]", image_info.filedetails.license);
    data.append("labor_contract[]", image_info.filedetails.labor_contract);
    data.append("other[]", image_info.filedetails.other);
    data.append("orderno", orderid)
    data.append("passportno",staffdata.passport_number)

    data.append("photo1",staffdata.photo)
    data.append("passport1",staffdata.passport)
    data.append("visa1",staffdata.visa)
    data.append("emirates_id1",staffdata.emirates_id)
    data.append("license1",staffdata.driving_license)
    data.append("labour_contract1",staffdata.labour_contract)
    data.append("other_doc1",staffdata.other_doc)
    data.append("uploadtype",'edit')

    let url = `${SERVER_URL}staff_fileupload.php`;

    axios.post(url, data, {
      // receive two parameter endpoint url ,form data
    })
      .then((res) => {
        console.log(res.data)
setmsg('Details Updated Successfully')
setmsgcolor('green')

      // navigate('/drivers')
        // then print response status

      }, error => {
        alert(error);
      

      });


  }
  

  return (
    <div className='wrapper-holder'>
      {<SidebarMenu />}
    
      <div className="main-panel ps-container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-primary">

                  <div className="row">
                      <div className="col-md-8">   <h4 className="card-title">Edit Staff</h4><p className="card-category">Complete your profile </p></div>
                      <div className="col-md-4"> <img src={displaypic} style={{width:'27%',float:'right',maxHeight:'120px'}}/></div>
                    </div>
                    
                  </div>
                  <div className="card-body">
                    <form id="myform" encType="multipart/form-data">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">



                            <label className>Branch <span style={{ color: 'red' }}>*</span></label>
                            <select id="branch" name="branch" value={staffdata.branch} onChange={(e)=>setstaffdata({...staffdata,branch:e.target.value})} className="form-control " >
                              <option value>Select Branch</option>
                              {
                                branches && branches != '' ? (
                                  <>
                                    {branches.map((e, i) => (
                                      branches && branches != "" ? (
                                        <>
                                          <option key={i} value={branches[i].id} >{branches[i].branch}</option>


                                        </>
                                      ) : null



                                    )
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )
                              }

                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className>Designation </label>
                            <input type="text" id="designation" defaultValue={staffdata.designation} name="designation" onChange={(e)=>setstaffdata({...staffdata,designation:e.target.value})} className="form-control" />
                         
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Fist Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="first_name" name="first_name" defaultValue={staffdata.first_name} onChange={(e)=>setstaffdata({...staffdata,first_name:e.target.value})} className="form-control" />
                          </div>
                        </div>

                        {/* <input type="file" />
                        <input type="file" onChange={handleChange2}/> */}
                        
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Last Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="last_name" name="last_name" className="form-control" defaultValue={staffdata.last_name} onChange={(e)=>setstaffdata({...staffdata,last_name:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Email <span style={{ color: '#fff' }}>*</span></label>
                            <input type="text" id="email" name="email" className="form-control" defaultValue={staffdata.email} onChange={(e)=>setstaffdata({...staffdata,email:e.target.value})} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Employee Number</label>
                            <input type="text" id="employee_number" name="employee_number" defaultValue={staffdata.employee_number} className="form-control" onChange={(e)=>setstaffdata({...staffdata,employee_number:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Mobile Number<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" id="mobile_number" name="mobile_number" defaultValue={staffdata.phone} className="form-control" onChange={(e)=>setstaffdata({...staffdata,phone:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Date Employed <span style={{ color: 'red' }}>*</span></label>
                            <input type="date" id="emp_date" name="emp_date" className="form-control" defaultValue={staffdata.date_employed} onChange={(e)=>setstaffdata({...staffdata,date_employed:e.target.value})}/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Citizenship <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="citizenship" name="citizenship" className="form-control" defaultValue={staffdata.citizenship}  onChange={(e)=>setstaffdata({...staffdata,citizenship:e.target.value})}/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Passport Number<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="passport_number" name="passport_number" onblur="exist_pass()" defaultValue={staffdata.passport_number} className="form-control" onChange={(e)=>setstaffdata({...staffdata,passport_number:e.target.value})}/>
                            <p id="reg_msg" style={{ color: 'red' }} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Passport Expiry Date</label>
                            <input type="date" id="pdrp_expiry" name="pdrp_expiry" className="form-control" defaultValue={staffdata.pdrp_expiry_date} onChange={(e)=>setstaffdata({...staffdata,pdrp_expiry_date:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4" style={{ display: 'none' }}>
                          <div className="form-group">
                            <label className="bmd-label-floating"> Code</label>
                            <input type="text" id="code" name="code" defaultValue={staffdata.employee_number} className="form-control"  />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4" style={{ marginTop: '-5px' }}>
                          <div className="form-group">
                            <label className="bmd-label-floating">Select Licence Type </label>
                            <select id="license_type" name="license_type" value={staffdata.license_type} onChange={(e)=>setstaffdata({...staffdata,license_type:e.target.value})} className="form-control" style={{ marginTop: '-17px' }}>
                              <option value>Select </option>
                              <option value="Manual">Manual</option>
                              <option value="Automatic">Automatic</option>
                              <option value="Heavy">Heavy</option>
                              <option value="Multi">Multi</option>
                              <option value="Motorcycle">Motorcycle</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Driving license Expiry Date </label>
                            <input type="date" id="license_expiry" name="license_expiry" defaultValue={staffdata.license_expiry_date} onChange={(e)=>setstaffdata({...staffdata,license_expiry_date:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Change status Date</label>
                            <input type="date" id="change_date" name="change_date" defaultValue={staffdata.change_date} onChange={(e)=>setstaffdata({...staffdata,change_date:e.target.value})} className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Work Permit Number</label>
                            <input type="text" id="work_permit_number" name="work_permit_number" defaultValue={staffdata.work_permit_number} onChange={(e)=>setstaffdata({...staffdata,work_permit_number:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Work Permit Expiry Date</label>
                            <input type="date" id="work_permit_expiry" defaultValue={staffdata.work_permit_expiry} name="work_permit_expiry" onChange={(e)=>setstaffdata({...staffdata,work_permit_expiry:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Date of Birth</label>
                            <input type="date" id="dob" name="dob" className="form-control" defaultValue={staffdata.dob}  onChange={(e)=>setstaffdata({...staffdata,dob:e.target.value})}/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Emirates ID Number</label>
                            <input type="text" id="eid_number" name="eid_number" defaultValue={staffdata.emirates_id_no} onChange={(e)=>setstaffdata({...staffdata,emirates_id_no:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Licence Number</label>
                            <input type="text" id="license_number" name="license_number" defaultValue={staffdata.license_no}  onChange={(e)=>setstaffdata({...staffdata,license_no:e.target.value})} className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Visa Type<span style={{ color: 'red' }}>*</span></label>
                            <select id="visa_type" name="visa_type" value={staffdata.visa_type}  onChange={(e)=>setstaffdata({...staffdata,visa_type:e.target.value})} className="form-control">
                            <option >select</option>
                              <option value={1}>Employment Visa (Timexpress)</option>
                              <option value={2}>Visit Visa</option>
                              <option value={3}>Freelance Visa</option>
                              <option value={4}>Cancelled</option>
                              <option value={5}>Employment Visa (other)</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Own Vehicle?</label>
                            <select id="own_vehicle" name="own_vehicle" value={staffdata.own_vehicle} onChange={(e)=>setstaffdata({...staffdata,own_vehicle:e.target.value})} className="form-control">
                              <option value>select</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Primary contact(Home Country )</label>
                            <div className="form-group">
                              <label className="bmd-label-floating"> </label>
                              <input type="text" id="home_contact" name="home_contact" defaultValue={staffdata.home_contact} onChange={(e)=>setstaffdata({...staffdata,home_contact:e.target.value})} className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Contact Number (Home Country )</label>
                            <div className="form-group">
                              <label className="bmd-label-floating"> </label>
                              <input type="text" id="home_contact_no"  defaultValue={staffdata.home_contact_no} name="home_contact_no" onChange={(e)=>setstaffdata({...staffdata,home_contact_no:e.target.value})} className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Residential Address</label>
                            <div className="form-group">
                              <label className="bmd-label-floating"> </label>
                              <textarea className="form-control" rows={1} name="res_address" defaultValue={staffdata.res_address} onChange={(e)=>setstaffdata({...staffdata,res_address:e.target.value})}  />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Residential City</label>
                            <select id="res_city" name="res_city"  value={staffdata.res_city} onChange={(e)=>setstaffdata({...staffdata,res_city:e.target.value})} className="form-control">
                              <option value>select city</option>
                              <option value="Dubai">Dubai</option>
                              <option value="Abudhabi">Abudhabi</option>
                              <option value="Sharjah">Sharjah</option>
                              <option value="Ajman">Ajman</option>
                              <option value="Ras Al Khaima">Ras Al Khaima</option>
                              <option value="Fujairah">Fujairah</option>
                              <option value="Umm Al Quwain">Umm Al Quwain</option>
                              <option value="Al Ain">Al Ain</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Special Notes</label>
                            <div className="form-group">
                              <label className="bmd-label-floating"> </label>
                              <textarea className="form-control" rows={2} name="notes" defaultValue={staffdata.notes} onChange={(e)=>setstaffdata({...staffdata,notes:e.target.value})} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h3 className="documents">Documents  <span style={{ color: '#00000', fontSize: '12px' }}> jpg,png,jpeg,pdf </span></h3>
                      {/*	<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
*/}
                      <style dangerouslySetInnerHTML={{ __html: "\n.custom-file-input:lang(en)~.custom-file-label::after {\n    content: \"Browse\";\n    background: #9c27b0;\n    color: #fff;\n    padding-bottom: 0px;\n    margin-bottom: 0px;\n}\n.custom-file-label::after {\n\t\n\tpadding: 0.7875rem 1rem !important;\n}\n.custom-file{\n\tbackground: #efebeb !important;\n}\n\n" }} />
                      
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Photo<span style={{ color: 'red' }}>*</span></label>
                      
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 0,'photo')}/>
                         
                          
                        </div>
                        <div className="col-md-6">
                        {staffdata.photo && staffdata.photo!=0?(
                            <a href={displaypic} className="viewform" target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Passport</label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'passport')} />
                         </div>
                         <div className="col-md-6">
                        {staffdata.passport && staffdata.passport!=0?(
                            <a href={displaypassport} className="viewform" target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Visa</label>
                         <input type="file" style={{float:'right'}}onChange={(e) => updatefiles(e.target.files[0], 1,'visa')}/>
                          </div>
                          <div className="col-md-6">
                        {staffdata.visa && staffdata.visa!=0?(
                            <a href={displayvisa} className="viewform" target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Emirates Id</label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'emirates_id')} />
                            </div>
                            <div className="col-md-6">
                        {staffdata.emirates_id && staffdata.emirates_id!=0?(
                            <a href={displayeid} className="viewform" target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Driving License<span style={{ color: 'red' }}>*</span></label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'license')}/>
                           </div>

                           <div className="col-md-6">
                        {staffdata.driving_license && staffdata.driving_license!=0?(
                            <a href={displaylicense} className="viewform" target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Labor Contract</label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'labor_contract')}/>
                            </div>
                            <div className="col-md-6">
                        {staffdata.labour_contract && staffdata.labour_contract!=0?(
                            <a href={displaylaborcontract} className="viewform" target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Other Doc</label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'other')}/>
                           </div>
                           <div className="col-md-6">
                        {staffdata.other_doc && staffdata.other_doc!=0?(
                            <a href={displayotherdoc} className="viewform" target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
    
                      <p style={{color:msgcolor,fontSize:'16px',marginTop:'2%'}}>{msg}</p>
                      {isload?(<img src={loading} style={{width:'5%'}}/>):null}
                      <a className="btn btn-primary pull-right" onClick={e=>submit_staff(e)} style={{ color: '#fff !important' }} id="addusr">Update Profile</a>
                      <div className="clearfix" />
                     </form>
                  </div>

                  
                  {staffvacation && staffvacation.appoval_status==1?(

<div className="card-body closeleaveform">
 <form id="myform2" encType="multipart/form-data">
<div className="leave_class">
<h3 className="documents" style={{marginTop: '0px'}}>Leave Information</h3>
<div className="row">
<div className="col-md-4">
<div className="form-group">
<label>Leave Type</label>
<input type="text" className="end form-control" defaultValue={staffvacation.leave_type} disabled />
</div>
</div>
<div className="col-md-4">
<div className="form-group" style={{marginTop: '13px'}}>
<label>Leave From</label>
<input type="text" className="end form-control" defaultValue={staffvacation.leave_from} disabled />
</div>
</div>
<div className="col-md-4">
<div className="form-group" style={{marginTop: '13px'}}>
<label>Leave To</label>
<input type="text" className="end form-control" defaultValue={staffvacation.leave_to} disabled />
</div>
</div>
</div>
<div className="row">
<div className="col-md-4">
<div className="form-group">
<label>Rejoined Date</label>
<input type="date" className="end form-control" onChange={(e)=>setstaffvacation({...staffvacation,rejoindate:e.target.value})} id="rejoin" name="end" />
</div>
</div>
</div>
<p id="sign2" />
<a className="btn btn-primary pull-right" onClick={(e)=>closeLeave(staffvacation.staff_id,staffvacation.id)} style={{color: '#fff !important', background: '#352c2c'}} id="addusr">Close Leave</a>
<div className="clearfix" />

</div></form>
<p>{msg2}</p>
</div>
):null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
