import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function Checklogin() {
    const navigate=useNavigate()
   
  useEffect(() => {
   if(!sessionStorage.getItem('userid')){
navigate('/');
   }else{
   
   }
  }, [])
  
}
