import React, { useEffect, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Modal from 'react-modal';
import loading from '../../images/spin.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar,faClose,faUserPlus,faAddressCard,faPenToSquare, faBatteryEmpty} from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const deleteicon = <FontAwesomeIcon icon={faClose}  style={{color:'red'}}/>
const editicon = <FontAwesomeIcon icon={faPenToSquare} style={{color:'blue'}}/>
const assignicon = <FontAwesomeIcon icon={faUserPlus} style={{color:'orange'}}/>
export default function Vehicles() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const API_PATH = `${SERVER_URL}get_vehicles.php`;
  const [isload, setisload] = useState(true)
  const [vehicles, setvehicles] = useState()
  const [data, setdata] = useState([])  
  const navigate=useNavigate()

  const current_date = new Date().toJSON().slice(0, 10);
 
 


  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { branch: branch, user_role: role,status:1,type:'normal',type2:'all' }
    }).then((res) => {
      console.log(res.data)
      
      setvehicles(res.data)
      setdata(res.data)
      setisload(false)
    })
      .catch((err) => console.log(err))

  }, [])

  const columns = [
       
    {
      name: <div className="datahead">Sl.No</div>,
      selector: "serial_no",
      // cell: (row) => <div style={{textAlign:'center'}}>
      //     <p style={{textAlign:'center'}}>{row.serial_no}</p>
        
      // </div>,
        sortable: true,
        // width: '80px !important'
    },
    {
        name: <div className="datahead">Vehicle Name</div>,
        selector: "vehicle_name",
        
        sortable: true
      },
      {
        name: <div className="datahead">Plate Number</div>,
        selector: "plate_number",
        sortable: true
      }
      ,
      {
        name: <div className="datahead">Reg.number</div>,
        selector: "reg_number",
        sortable: true,
        width: '90px !important'
      }
      ,
      {
        name: <div className="datahead">Mileage</div>,
        selector: "mileage",
        sortable: true
      },
      {
        name: <div className="datahead">Driver</div>,
        selector: "drivername",
        sortable: true
      }
      ,
      {
        name: <div className="datahead">Branch</div>,
        selector: "branch",
        sortable: true
      }
      ,
     
      {
        name: <div className="datahead">Rental/ Lease Expiry</div>,
        selector: "purchase_to",
        sortable: true,
        width: '90px !important'
      },
      {
        name: <div className="datahead">Assignment Status</div>,
        selector: "job_sts",
        cell: (row) => <div>
          {row.assignment_sts=='Expired'?(<p style={{textAlign:'left',color:'red',fontWeight:'bold'}}>{row.assignment_sts}</p>):row.assignment_sts=='Free'?(<p style={{textAlign:'left',color:'blue',fontWeight:'bold'}}>{row.assignment_sts}</p>):
          (<p style={{textAlign:'left',color:'green',fontWeight:'bold'}}>{row.assignment_sts}</p>)}
         
         
      </div>,
        sortable: true,
        width: '90px !important'
      },
      
      {
        name:"Action",
        cell: (row) => <div className="btn-group" role="group" aria-label="Basic example">
        <a  id={row.id} onClick={()=>deleteVehicle(row.id,row.drivername)} className="">{deleteicon}</a>&nbsp; &nbsp;<a  id={row.id} onClick={()=>editVehicle(row.id)} className="">{editicon}</a>&nbsp;&nbsp;
        <a   id={row.id} onClick={()=>setModalIsOpenToTrue(row.id)} className="">{assignicon}</a>

       </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false
      },
      
    
  
  ];
  const tableData = {
    columns,
    data
  };

  function deleteVehicle(val,val1){
  
           if(val1){        alert(val1)
            if(val1.length>2){
              alert("You have to close the current assignment of the driver before removing the vehicle")
            }
           }else{
         
    window.confirm('Are you sure you wish to delete this item?') ? deletethisvehicle(val) : deletethisvehicle(0)
  }
}
const API_PATH2=`${SERVER_URL}vehicle_actions.php`;
function deletethisvehicle(val){
  if(val>0){
    
  axios({
      method: 'post',
      url: `${API_PATH2}`,
      data: {user:user,record:val,type:'delete'}
  })
  .then((res)=>{
      console.log(res.data)
      // navigate('/my-account/address-book')
     window.location.reload();

  })
  .catch(err=>console.log(err))
 
}
}

const [reload, setreload] = useState(0)
const [modalIsOpen,setModalIsOpen] = useState(false);
const [vehilcledata, setvehilcledata] = useState({
  reg_number:'',
  vehicle_name:''
})
const [currentassign, setcurrentassign] = useState({

  driver_id:'',
  branch_id:'',
  from_date:'',
  from_time:'',
  to_date:'',
  to_time:'',
  notes:'',
  driver_name:''
})

const [oldassign, setoldassign] = useState({
  driver_id:'',
  branch_id:'',
  from_date:'',
  from_time:'',
  to_date:'',
  to_time:'',
  notes:'',
  driver_name:''
})
const [selectDriver, setSelectDriver] = useState();
const [vehicle, setvehicle] = useState()
const [drivers, setdrivers] = useState([])
const [branches, setbranches] = useState([])
const [msg, setmsg] = useState()
function setModalIsOpenToTrue(val){
  setmsg('')
  setmsg2('')
  setallowed(1)
  axios({
    method: 'post',
    url: `${API_PATH2}`,
    data: {user:user,record:val,type:'details'}
})
.then((res)=>{console.log(res.data.assign_details)
  
    setvehicle(val)
    setvehilcledata(res.data.vehicle_details)
    setdrivers(res.data.drivers)
    setcurrentassign(res.data.assign_details)
    setoldassign(res.data.assign_details)
    setbranches(res.data.branches)
    setSelectDriver({...selectDriver,label:res.data.assign_details.driver_name,value:res.data.assign_details.driver_id})
    setModalIsOpen(true)


})
.catch(err=>console.log(err))

}

const options = drivers?.map(data => ({
  label: `${data.driver_name}`,
  value: `${data.id}`
}))



// useEffect(() => {
//   setSelectDriver({...selectDriver,label:currentassign.driver_name,value:currentassign.driver_id})
// }, [currentassign])

console.log(selectDriver)

function setModalIsOpenToFalse(){
  setModalIsOpen(false)
  if(reload==1){
    window.location.reload();
  }
 
}


function editVehicle(val){
  // navigate(`/edit-vehicle/${val}`);
  window.open(`/edit-vehicle/${val}`,'_blank')
}

const [msg2, setmsg2] = useState('')
const [allowed, setallowed] = useState(1)
function showBranch(val,label){  
//setcurrentassign({...currentassign,driver_id:val})
  axios({
    method: 'post',
    url: `${API_PATH2}`,
    data: {user:user,driverid:val,vehicle:vehicle,type:'getbranch'}
})
.then((res)=>{
 // setSelectDriver({...selectDriver,label:'wwwww',value:3})
     setcurrentassign({...currentassign,branch_id:res.data.branch_details.branch,driver_id:val})
    setmsg2(res.data.message)
      setallowed(res.data.allowed)
      console.log('allowed, ',res.data.allowed)
      if(res.data.allowed==0){
        setcurrentassign(oldassign)
        console.log('ccc-',oldassign.driver_name)
        setSelectDriver({...selectDriver,label:oldassign.driver_name,value:oldassign.driver_id})
   
      }else{
       
        setSelectDriver({...selectDriver,label:label,value:val})
      }
      
    // navigate('/my-account/address-book')
  // window.location.reload();

})
.catch(err=>console.log(err))

}


function assign_vehicle(){
  console.log(currentassign)
  console.log(vehicle)
  setreload(1)
  setmsg2('')
  if(currentassign.driver_id >0){ setisload(true)
  if(currentassign.from_date <= currentassign.to_date){
    axios({
      method:'post',
      url:API_PATH2,
      data:{user:user,vehicle:vehicle,currentassign:currentassign,type:'assign'}
    })
    .then((res)=>{
      setisload(false)
      setmsg(res.data)
    
      setTimeout(()=> {
        window.location.reload(); 
    }, 2000); 
    })
    .catch((err)=>console.log(err.message))
  }else{
    setisload(false)
    setmsg2('To Date should be greater or same as from date')
  }
}else{
  setisload(false)
  setmsg2('Please enter all the required fields')
}

}

function closeAssign(val){
           
  window.confirm('Are you sure to close this assignment?') ? close_assignment(val) : close_assignment(0)

}
function close_assignment(val){ 
  let todate=new Date(currentassign.to_date).toJSON().slice(0, 10);
 
  if(val >0){
    setisload(true)
    setreload(1)
  if(currentassign.from_date <= currentassign.to_date){

    if(current_date >= todate){
      setmsg2('')
axios({
      method:'post',
      url:API_PATH2,
      data:{user:user,vehicle:vehicle,currentassign:currentassign,type:'close'}
    })
    .then((res)=>{
      setisload(false)
      setmsg(res.data)
     
      setTimeout(()=> {
        window.location.reload(); 
    }, 2000); 
    })
    .catch((err)=>console.log(err.message))


    }else{
      setisload(false)
      setmsg2('Closing to Date cannot be a future date.')
    }
    
  }else{
    setisload(false)
    setmsg2('To Date should be greater or same as from date')
  }
}
}
  return (
    <div className='wrapper-holder driver'>
    
            
    {<SidebarMenu />}



    <div className="main-panel ps-container">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Modal isOpen={modalIsOpen} ariaHideApp={false}>
   
   <>
   
  
         <div className="modal-header">
           <h4 className="modal-title">Assign Vehicle To Driver </h4>
           <button type="button" onClick={setModalIsOpenToFalse}  className="close" data-dismiss="modal">×</button>
         </div>
         {/* Modal body */}
         {/* <div className="modal-body"> */}
           <div className="container-fluid modalcontainer">
             <div className="row">
               <div className="col-md-12">
                 {/* <div className="card">
                   <div className="card-body"> */}
                   <form id="myform" encType="multipart/form-data" style={{marginTop:'3%'}}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className htmlFor="vehicle_name">Vehicle<span style={{color:'red'}}>*</span> </label>
              <input type="text" id="vehicle_name2" name="vehicle_name2" className="form-control" disabled defaultValue={`${vehilcledata.reg_number}-${vehilcledata.vehicle_name}`} />
              <input type="hidden" id="vehicle_name" name="vehicle_name" className="form-control" disabled defaultValue />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Driver<span style={{color:'red'}}>*</span> </label>

              <br/><Select
        className="basic-single"
        classNamePrefix="select"
        onChange={(choice) => showBranch(choice.value,choice.label)}
        value={selectDriver}
        // onChange={(e)=>setdriverdata({...driverdata,branch:options.value})}
        name="color"
        options={options}
      />
              {/* <select id="driver" name="driver" className="form-control" onChange={(e)=>showBranch(e.target.value)}>
                <option value>select</option>
                 {drivers && drivers!=''?(
                  <>
                  {
                    drivers.map((val,i)=>(
                      <option key={i} value={val.id} selected={val.id==currentassign.driver_id}>{val.driver_name}</option>
                    )
                    )
                  }
                  </>
                 ):null}
              </select> */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="branch">Branch<span style={{color:'red'}}>*</span> </label>
              <select id="branch" name="branch" className="form-control "  disabled >
                <option value>Select Branch</option>
                {branches && branches!=''?(
                  <>
                  {
                    branches.map((val,i)=>(
                      <option key={i} value={val.id} selected={val.id==currentassign.branch_id}>{val.branch}</option>
                    )
                    )
                  }
                  </>
                 ):null}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label style={{width:'100%'}}>Assigned From<span style={{color:'red'}}>*</span> </label>
              <input type="datetime-local" className="start" id="start" name="start" onChange={(e)=>setcurrentassign({...currentassign,from_date:e.target.value})} defaultValue={currentassign.from_date} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label style={{width:'100%'}}>Assigned To<span style={{color:'red'}}>*</span></label>
              <input type="datetime-local"  className="end" id="end" name="end" onChange={(e)=>setcurrentassign({...currentassign,to_date:e.target.value})} defaultValue={currentassign.to_date}  />
            </div>
          </div>
        </div> 
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Special Notes</label>
              <div className="form-group">
                <label className="bmd-label-floating"> </label>
                <textarea className="form-control" rows={5} id="notes" name="notes" onChange={(e)=>setcurrentassign({...currentassign,notes:e.target.value})} defaultValue={currentassign.notes} />
              </div>
            </div>
          </div>
        </div>
        {msg2 && msg2!=''?(
  <p  style={{color:'#fff',background:'red',padding:'1%'}}>{msg2}</p>
        ):null}
      
        <p style={{color: '#9c27b0', fontWeight: 500}}>Update Assignment: This will create a new assignment or update the existing assignment.</p>
        <p style={{color: 'red', fontWeight: 500}}>Close Assignment: this will close the existing assignments of vehicle. Both the driver and vehicle become unassigned( Free ). Please update the Actual <b>Assigned To</b> before closing.</p>
       {allowed==1?(
        <a className="btn btn-primary pull-right " id="update_button" name="update_button" onClick={assign_vehicle} style={{color: '#fff !important'}}>Update Assignment</a>
       ):null
    }
        {oldassign.branch_id && oldassign.branch_id >0?(
          <a className="btn btn-primary  " id="close_button" name="close_button" onClick={()=>closeAssign(vehicle)} style={{backgroundColor: 'red', color: '#fff !important'}}>Close Assignment</a>
        
        ):null}
        <div className="clearfix" />
        
      </form>
      <p style={{color:'green',fontWeight:'bold'}}>{msg}</p>
      {isload?(<img src={loading} style={{width:'5%'}}/>):null}
      
                      {/* </div>
                      </div> */}
                      </div>
                      </div>
                      </div>
                      <div className="modal-footer">
            <button type="button" className="btn btn-secondary closebtn" onClick={setModalIsOpenToFalse} >Close</button>
        
      </div>
                      </>
                      </Modal>
            <div className="card">
                  <div className="card-header card-header-primary">
                    <h4 className="card-title">Vehicles</h4>
                    <p className="card-category"></p>
              
      
                  </div>
                  <div className="card-body">
                  {isload?(
  <div style={{textAlign:"center"}}>
  <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>

  </div>
):(<DataTableExtensions {...tableData}>
  <DataTable
    columns={columns}
    data={data}
    noHeader
    defaultSortField="id"
    // sortIcon={<SortIcon />}
    defaultSortAsc={true}
    pagination
    highlightOnHover
    dense
  />
</DataTableExtensions>
)}
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
  )
}
