import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import loading from '../../images/spin.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar,faClose,faCalendarDays,faAddressCard,faPenToSquare, faBatteryEmpty} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import '../../Form.css'
import Checklogin from './Checklogin';
import { Link, useNavigate } from 'react-router-dom';
const car = <FontAwesomeIcon icon={faCar} style={{color:'blue'}}/>
const deleteicon = <FontAwesomeIcon icon={faClose}  style={{color:'red'}}/>
const calender = <FontAwesomeIcon icon={faCalendarDays}  style={{color:'green'}}/>
const addresscard = <FontAwesomeIcon icon={faAddressCard} style={{color:'purple'}}/>
const editicon = <FontAwesomeIcon icon={faPenToSquare} style={{color:'blue'}}/>


export default function KmReport() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const navigate=useNavigate()
  const API_PATH = `${SERVER_URL}get_drivers.php`;
  const [drivers, setdrivers] = useState()
  const [isload, setisload] = useState(true)
  const [showload, setshowload] = useState(false)
  const [data, setdata2] = useState([])
  const [modalIsOpen1,setModalIsOpen1] = useState(true);
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [modalIsOpen2,setModalIsOpen2] = useState(false);



  let today = new Date();
  let dd = today.getDate();
  
  let mm = today.getMonth()+1; 
  let yyyy = today.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 
  
  if(mm<10) 
  {
      mm='0'+mm;
  } 
  today = yyyy+'-'+mm+'-'+dd;
  const [todaydate, settodaydate] = useState(today)

const years = Array.from(new Array(3),( val, index) => index + yyyy);
const [thisyear, setthisyear] = useState(yyyy)
  const columns = [
       
   
    {
        name: <div className="datahead">Reg. Number</div>,
        selector: "reg_number",
        
        sortable: true
      },
    {
        name: <div className="datahead">KM</div>,
        selector: "km",
        
        sortable: true
      },
    {
        name: <div className="datahead">Updated Date</div>,
        selector: "km_updated_date",
        
        sortable: true
      },
    {
        name: <div className="datahead">Driver</div>,
        cell: (row) => <div>
   {row.driver_name}
    </div>,
        sortable: true,
        width:"300px !important",
        padding:"2% !important"
      },
    {
        name: <div className="datahead">Branch</div>,
        selector: "branch",
        
        sortable: true
      },
    {
        name: <div className="datahead">Status</div>,
        selector: "km_status",
        
        sortable: true
      },


     
   
     
  
  ];
  const tableData = {
    columns,
    data
  };

  const [searchdata, setsearchdata] = useState({
    year:yyyy,
    month:''
  })
 





const [msg, setmsg] = useState()


function setModalIsOpenToFalse(){
  setModalIsOpen(false)
}
function setModalIsOpenToFalse1(){
    setModalIsOpen1(false)
  }
  


    const API_Search=`${SERVER_URL}km_report.php`
    function fuelreport(event){
        event.preventDefault();
        console.log(searchdata)
        if(searchdata.year !='' && searchdata.month!=''){
          setshowload(true)
            axios({
                method:'post',
                url:API_Search,
                data:{ branch: branch, user_role: role,searchdata:searchdata,type:'report'}
            }).then((res)=>{
                console.log(res.data)
              
                setdata2(res.data)
                setisload(false)
                setshowload(false)
                setModalIsOpen1(false)

            }).catch((err)=>console.log(err.message))
        }
    }

const [totaluse, settotaluse] = useState([])
const [totaluse2, settotaluse2] = useState([])

const [parcel_info, setparcel_info] = useState({
  parceldetails: {
    totaluse: [],
    parcel_value: []
  }
})

// totaluse => 
let nextId=0;
 function getusage(val,i){
axios({
  method:'post',
  url:API_Search,
  data:{ searchdata:searchdata,vehicle:val,type:'findusage'}
}).then((res)=>{
  settotaluse({...totaluse,[i]:res.data})

}).catch((err)=>console.log(err.message))
// console.log(`${totaluse.i}`)


       }

       console.log(totaluse)

  return (
    
    <div className='wrapper-holder driver'>
    
            
      {<SidebarMenu />}

  
  
      <div className="main-panel ps-container fuelreport">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">

              <Modal isOpen={modalIsOpen1} ariaHideApp={false}>
   
   <>
   
  
         <div className="modal-header">
           <h4 className="modal-title">KM Report</h4>
           <button type="button" onClick={setModalIsOpenToFalse1}  className="close" data-dismiss="modal">×</button>
         </div>
         {/* Modal body */}
         {/* <div className="modal-body"> */}
           <div className="container-fluid modalcontainer">
             <div className="row">
               <div className="col-md-12">
                 <div className="card">
                   <div className="card-body">
                     <form id="myform" encType="multipart/form-data">
                       <div className="row">
                         <div className="col-md-6">
                           <div className="form-group" style={{marginTop:'0px'}}>
                           <label>Year</label>
                           <div className="form-group">
                              <select id="year" name="year"  defaultValue={thisyear} onChange={(e)=>setsearchdata({...searchdata,year:e.target.value})}  className="form-control">
                              <option value={thisyear}>{thisyear}</option>
                              <option value={thisyear-1}>{thisyear-1}</option>
                              <option value={thisyear-2}>{thisyear-2}</option>
                              </select>
                            </div>
                             </div>
                         </div>
                         <div className="col-md-6">
                         <label>Month</label>
                         <div className="form-group">
                              <select id="month" name="month"   onChange={(e)=>setsearchdata({...searchdata,month:e.target.value})}  className="form-control">
                              <option>Select</option>
                              <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                            </div>
                         </div>
                       </div>
                       
                      
                      
                     
                       
                       <p id="sign" />
                       <a className="btn btn-primary pull-right" onClick={e=>fuelreport(e)} style={{color: '#ffffff !important'}}>Get Report</a>
                       <div className="clearfix" />
                       {showload?(
                         <img src={loading} style={{width:'5%',marginLeft:'11%'}}/>
                       ):null}
                       <p>{msg}</p>
                     </form>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         {/* </div> */}
         {/* Modal footer */}
         <div className="modal-footer">
           <button type="button" className="btn btn-secondary closebtn" onClick={setModalIsOpenToFalse1} >Close</button>
       
     </div>
     </>
                 </Modal>
             
            
                  {/* visa application  */}

                 
 
                <div className="card">
                  <div className="card-header card-header-primary">
                    <h4 className="card-title">KM Report</h4>
                    <p className="card-category"></p>
              
                  </div>
                  <div className="card-body">
                  {isload?(
  <div style={{textAlign:"center"}}>
  <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>

  </div>
):(<DataTableExtensions {...tableData}>
  <DataTable
    columns={columns}
    data={data}
    noHeader
    defaultSortField="id"
    // sortIcon={<SortIcon />}
    defaultSortAsc={true}
    pagination
    highlightOnHover
    dense
  />
</DataTableExtensions>
)}

                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
