import axios from "axios";
import { useEffect } from "react";
import SidebarMenu from "../SidebarMenu";
import { Link } from "react-router-dom";
import { useState } from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar,faClose,faCalendarDays,faAddressCard,faPenToSquare, faBatteryEmpty} from '@fortawesome/free-solid-svg-icons'



const deleteicon = <FontAwesomeIcon icon={faClose}  style={{color:''}}/>
const editicon = <FontAwesomeIcon icon={faPenToSquare} style={{color:''}}/>
const UserList = () => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const role = sessionStorage.getItem('role')
    const user = sessionStorage.getItem('userid')  
    
    useEffect(() => {
        getUserList();
    }, []);

    const [isload, setisload] = useState(true);
    const [data, setdata2] = useState([]);
    const getUserList = async () => {
        const API_PATH = `${SERVER_URL}user.php`;
        const passval = {
            type: "userlist",
            user: "user"
        };
        await axios
            .post(API_PATH, passval)   
            .then(res => {
                // console.log(res);
                setdata2(res.data.list)
                setisload(false);                
            })
            .catch((err) => {
                console.log(err);
            }) 
    }
    
    const columns = [
        {
            name: "Name",
            selector: "user",
            sortable: true,
        },

        {
            name: "Username",
            selector: "username",
            sortable: true,
        },

        {
            name: "Branch",
            selector:"branch_name",
            sortable: true,
        },

        {
            name: "Actions",
            cell: (row) => <>
                <Link to={`/user-edit/${row.id}`}><button className="btn btn-primary" style={{padding: '6px 12px'}}>{editicon}</button></Link>
                {/* <a className="btn btn-danger" onClick={(e) => getDeleteuser(e.target.value)} value={row.id} style={{ color: '#fff' }}>Delete</a> */}
                <a className="btn btn-danger" value={row.id} onClick={(e) => getDeleteuser(e.target.attributes.getNamedItem("value").value)} style={{ color: '#fff',padding: '6px 12px' }}>{deleteicon}</a>
            </>,
        },
    ]

    const tableData = {
      columns,
      data
    };

    function getDeleteuser(val){
        // alert(val);
        window.confirm("Are you sure to delete this user!")
        ?condeleteuser(val)
        :condeleteuser(0)
    }

    const condeleteuser = async (deleteuser) => {
        if(deleteuser>0){
            const API_PATH = `${SERVER_URL}user.php`;
            const passval = {
                type: 'deleteuser',
                deleteuserid: deleteuser.toString()
            };
            await axios
                .post(API_PATH, passval)
                .then(res => {
                    console.log(res);
                    if(res.data.status == '1'){
                        alert(res.data.message);
                        window.location.reload(); 
                    }else{
                        alert(res.data.message);
                        window.location.reload(); 
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
            
        }
    }


    return (        
        <div className="wrapper-holder">
            <SidebarMenu/>
            <div className="main-panel ps-container">                
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">                                  
                                    <div className="card-header card-header-primary">
                                        <h4 className="card-title ">Users <span style={{ float: 'right' }}> <Link to="/add-user">+</Link></span></h4>
                                        <p className="card-category"></p>
                                    </div>
                                    
                                    <div className="card-body">
                                    {
                                        isload?(
                                            <div style={{textAlign:"center"}}>
                                                <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>
                                            </div>
                                        ):(<DataTableExtensions {...tableData}>
                                            <DataTable 
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                // sortIcon={<SortIcon />}
                                                defaultSortAsc={true}
                                                pagination
                                                highlightOnHover
                                                dense
                                            />
                                            </DataTableExtensions>
                                        )
                                    }
                                    </div> 

                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    )
}
export default UserList;