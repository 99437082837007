import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import loading from '../../images/spin.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar,faCalendarDays,faPenToSquare,faHandshake} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import '../../Form.css'
import Checklogin from './Checklogin';
import { Link, useNavigate } from 'react-router-dom';
const car = <FontAwesomeIcon icon={faCar} style={{color:'blue'}}/>
const handshake = <FontAwesomeIcon icon={faHandshake}  style={{color:'red'}}/>
const editicon = <FontAwesomeIcon icon={faPenToSquare} style={{color:'blue'}}/>
export default function RetiredDrivers() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const navigate=useNavigate()
  const API_PATH = `${SERVER_URL}get_drivers.php`;
  const [drivers, setdrivers] = useState()
  const [isload, setisload] = useState(true)
  const [showload, setshowload] = useState(false)
  const [data, setdata2] = useState([])
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [modalIsOpen2,setModalIsOpen2] = useState(false);
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { branch: branch, user_role: role,status:0 ,type:'normal'}
    }).then((res) => {
      console.log(res.data)
      
      setdrivers(res.data)
      setdata2(res.data)
      setisload(false)
    })
      .catch((err) => console.log(err))

  }, [])

  const [driver, setdriver] = useState()

  let today = new Date();
  let dd = today.getDate();
  
  let mm = today.getMonth()+1; 
  let yyyy = today.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 
  
  if(mm<10) 
  {
      mm='0'+mm;
  } 
  today = yyyy+'-'+mm+'-'+dd;
  const [todaydate, settodaydate] = useState(today)

  const columns = [
       
    {
      name: <div className="datahead">Full name</div>,
      selector: "full_name",
      sortable: true
    },
    {
        name: <div className="datahead">Branch</div>,
        selector: "branch",
        
        sortable: true
      },
      {
        name: <div className="datahead">Mobile</div>,
        selector: "phone",
        sortable: true
      }
      ,
      {
        name: <div className="datahead">Valid Drivers License</div>,
        selector: "license_expiry_date",
        sortable: true,
        width: '90px !important'
      }
      ,
      {
        name: <div className="datahead">Date Employed</div>,
        selector: "date_employed",
        sortable: true
      },
      
      {
        name: <div className="datahead">Driver Status</div>,
        selector: "job_sts",
        cell: (row) => <div>
          <p style={{textAlign:'left',color:'green',fontWeight:'bold'}}>{row.job_sts}</p>
          {row.assignment_sts==''?null:(
 <p style={{textAlign:'left',color:'red'}}>{row.assignment_sts}</p>
          )}
         
      </div>,
        sortable: true,
        width: '90px !important'
      }
      ,
      {
        name:"Action",
        cell: (row) => <div className="btn-group" role="group" aria-label="Basic example">
       &nbsp; &nbsp;<a  id={row.id} onClick={()=>editDriver(row.id)} className="">{editicon}</a>&nbsp;&nbsp;
        <a  id={row.id} onClick={()=>setModalIsOpenToTrue(row.id)} className="">{handshake}</a>&nbsp; &nbsp;
       </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false
      },
      ,
      {
        name:"View Vehicles",
        cell: (row) => <div className="btn-group" role="group" aria-label="Basic example">
        <a  id={row.id} onClick={()=>viewVehicles(row.id)} className="">{car}</a></div>,
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false,
        width: '60px !important'
      },
  
  ];
  const tableData = {
    columns,
    data
  };

 
  function deleteAddress(val){
           
    window.confirm('Are you sure you wish to delete this item?') ? deletethisaddress(val) : deletethisaddress(0)

}

const [settlementdata, setsettlementdata] = useState({
  reason:'',
  notes:'',
  type_of_leaving:'',
  resign_form:'',
  cancel_date:'',
  resign_date:'',
  settle_form:'',
  cancel_form:''
})
const [joindata, setjoindata] = useState({
  driver_name:'',
  change_date:'',
  date_employed:'',
  id:'',
})


const [msg, setmsg] = useState()
const API_PATH1=`${SERVER_URL}get_settlement_details.php`;
function setModalIsOpenToTrue(val){
  setdriver(val)
  axios({
    method:'post',
     url:`${API_PATH1}`,
     data:val
  }).then((res)=>{
   
   if(res.data.settlementdetails!=null){
    setsettlementdata(res.data.settlementdetails)
   
   }else{
    setsettlementdata({...settlementdata,  reason:'',
    notes:'',
    type_of_leaving:'',
    driver_id:'',
    cancel_date:'',
    resign_date:'',
    resign_form:'',
    settle_form:'',
    cancel_form:''
  })

   }
   if(res.data.joindetails!=null){
    setjoindata(res.data.joindetails)
   }
   setmsg('')
   setModalIsOpen(true)
    
  }).catch(err=>console.log(err.message))
}

function setModalIsOpenToFalse(){
  setModalIsOpen(false)
}







function deletethisaddress(val){
    if(val>0){
       
   const API_PATH=`${SERVER_URL}user_actions.php`;
    axios({
        method: 'post',
        url: `${API_PATH}`,
        data: {user:user,record:val,type:'delete'}
    })
    .then((res)=>{
        console.log(res.data)
        // navigate('/my-account/address-book')
       window.location.reload();

    })
    .catch(err=>console.log(err))
   
}
}

const [image_info, setimage_info] = useState({
  filedetails:{
    settle_form:'',
   resign_form:'',
   cancel_form:''
  }
}) 

function updatefiles(val, i,k) {
 
  const clonedListing = { ...image_info.filedetails };

if(k=='settle_form'){
setimage_info({
  filedetails: {
    ...clonedListing,
    settle_form: val
  }
});
}else if(k=='resign_form'){
setimage_info({
  filedetails: {
    ...clonedListing,
    resign_form: val
  }
});
}else if(k=='cancel_form'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      cancel_form: val
    }
  });
}
}


 const API_Settle=`${SERVER_URL}user_actions.php`
 function submitSettlement(event){
event.preventDefault();
  if(settlementdata.cancel_date !='' && settlementdata.resign_date && settlementdata.type_of_leaving
  && (settlementdata.resign_form!=''||image_info.filedetails.resign_form !='') && (settlementdata.settle_form!=''||image_info.filedetails.settle_form !='') && (settlementdata.cancel_form!=''||image_info.filedetails.cancel_form !='')){
   axios({
    method:'post',
    url:API_Settle,
    data:{user:user,settlementdata:settlementdata,driver:driver,type:'settle'}
   })
   .then((res)=>{
    console.log(res.data)
    if(res.data>1){ 
      saveimages(event, res.data)
    }
  })
   .catch((err)=>console.log(err.message))

  }else{
    setmsg('Please enter all the required fields')
  }
 
 }

 function saveimages(event, settleid) {
  console.log(image_info.filedetails);
      event.preventDefault();
      const data = new FormData();
      data.append("settle_form[]", image_info.filedetails.settle_form);
      data.append("resign_form[]", image_info.filedetails.resign_form);
      data.append("cancel_form[]", image_info.filedetails.cancel_form);
      data.append("settleid", settleid)
      data.append("passportno",joindata.passport_number)
      data.append("settle_form2",settlementdata.settle_form)
      data.append("resign_form2",settlementdata.resign_form)
      data.append("cancel_form2",settlementdata.cancel_form)

      let url = `${SERVER_URL}settle_fileupload.php`;
  
      axios.post(url, data, {
        // receive two parameter endpoint url ,form data
      })
        .then((res) => {
          console.log('heree',res.data)
          setshowload(false)
          setmsg(res.data.msg)
         // navigate('/drivers')
          // then print response status
        
        }, error => {
          alert(error);
        
  
        });
  
  
    }

 

  function viewVehicles(val){
     navigate(`/view-vehicles/${val}`)
    }
  function editDriver(val){
     navigate(`/edit-driver/${val}`)
    }

  return (
    
    <div className='wrapper-holder driver'>
    
            
      {<SidebarMenu />}

  
  
      <div className="main-panel ps-container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
             
              <Modal isOpen={modalIsOpen} ariaHideApp={false}>
   
    <>
    
   
          <div className="modal-header">
            <h4 className="modal-title">Settlement </h4>
            <button type="button" onClick={setModalIsOpenToFalse}  className="close" data-dismiss="modal">×</button>
          </div>
          {/* Modal body */}
          {/* <div className="modal-body"> */}
            <div className="container-fluid modalcontainer">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <form id="myform" encType="multipart/form-data">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop:'0px'}}>
                            <label>Driver</label>
                              <input type="text" id="driver" name="driver" defaultValue={joindata.driver_name}  className="form-control" disabled />
                              <input type="hidden" id="driver_id" name="driver_id" className="form-control" disabled defaultValue />
                            </div>
                          </div>
                          <div className="col-md-6">
                          <label>Type Of Leaving</label>
                            <div className="form-group">
                              <select id="leave_type" name="leave_type" onChange={(e)=>setsettlementdata({...settlementdata,type_of_leaving:e.target.value})} defaultValue={settlementdata.type_of_leaving && settlementdata.type_of_leaving!=''?settlementdata.type_of_leaving:''} className="form-control">
                                <option>Select Type</option>
                                <option value="Resignation">Resignation</option>
                                <option value="Termination">Termination</option>
                                <option value="Labor Case">Labor Case</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Resigned / Terminated Date <span style={{color: 'red'}}> * </span></label>
                              <input type="date" className="start form-control" onChange={(e)=>setsettlementdata({...settlementdata,resign_date:e.target.value})} defaultValue={settlementdata.resign_date} id="start" name="start" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Cancellation Date<span style={{color: 'red'}}> * </span></label>
                              <input type="date" className="end form-control" id="end" onChange={(e)=>setsettlementdata({...settlementdata,cancel_date:e.target.value})} defaultValue={settlementdata.cancel_date} name="end"  />
                            </div>
                          </div>
                        </div> 
                       
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Reason of Resignation/ Termination</label>
                              <div className="form-group">
                                <label className="bmd-label-floating"> </label>
                                <textarea className="form-control" rows={3} onChange={(e)=>setsettlementdata({...settlementdata,reason:e.target.value})} name="notes" defaultValue={settlementdata.reason} id="notes" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Special Notes</label>
                              <div className="form-group">
                                <label className="bmd-label-floating"> </label>
                                <textarea className="form-control" rows={3} onChange={(e)=>setsettlementdata({...settlementdata,notes:e.target.value})} name="notes" defaultValue={settlementdata.notes} id="notes" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="w-100">Settlement Form <span style={{color: 'red'}}> * </span>
                              <div className="custom-file">
                              <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'settle_form')} />
                           
                              </div>
                            </label>
                            <input type="hidden" name="leave_form1" id="leave_form1" />  
                            <div className="view_form">			   
                            </div>
                            {
                          settlementdata.settle_form && settlementdata.settle_form!='' && settlementdata.settle_form!=0?(
                             <a href={settlementdata.settle_form} className="view_form" target="_blank">View Form</a>
                    
                          ):null
                        }		   
                          </div>
                         
                          <div className="col-md-6">
                            <label className="w-100">Cancellation <span style={{color: 'red'}}> * </span>
                              <div className="custom-file">
                              <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'cancel_form')} />
                           
                              </div>
                            </label>
                            <input type="hidden" name="leave_form1" id="leave_form1" />  
                            <div className="view_form">			   
                            </div>	
                            {
                          settlementdata.cancel_form && settlementdata.cancel_form!='' && settlementdata.cancel_form!=0 ?(
                             <a href={settlementdata.cancel_form} className="view_form" target="_blank">View Form</a>
                    
                          ):null
                        }	   
                          </div>
                         

                          <div className="col-md-6">
                            <label className="w-100">Resignation/Termination<span style={{color: 'red'}}> * </span>
                              <div className="custom-file">
                              <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'resign_form')} />
                           
                              </div>
                            </label>
                            <input type="hidden" name="leave_form1" id="leave_form1" />  
                            <div className="view_form">			   
                           
                            {
                          settlementdata.resign_form && settlementdata.resign_form!='' && settlementdata.resign_form !=0 ?(
                             <a href={settlementdata.resign_form} className="view_form" target="_blank">View Form</a>
                    
                          ):null
                        }	    </div>	
                          </div>
                         
                        </div>
                        
                        <p id="sign" />
                        <a className="btn btn-primary pull-right" onClick={e=>submitSettlement(e)} style={{color: '#ffffff !important'}}>Update Profile</a>
                        <div className="clearfix" />
                        {showload?(
                          <img src={loading} style={{width:'5%',marginLeft:'11%'}}/>
                        ):null}
                        <p>{msg}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
          {/* Modal footer */}
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary closebtn" onClick={setModalIsOpenToFalse} >Close</button>
        
      </div>
      </>
                  </Modal>

                  {/* visa application  */}

                

 
                <div className="card">
                  <div className="card-header card-header-primary">
                    <h4 className="card-title">Offloaded Drivers</h4>
                    <p className="card-category"></p>
              
      
                  </div>
                  <div className="card-body">
                  {isload?(
  <div style={{textAlign:"center"}}>
  <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>

  </div>
):(<DataTableExtensions {...tableData}>
  <DataTable
    columns={columns}
    data={data}
    noHeader
    defaultSortField="id"
    // sortIcon={<SortIcon />}
    defaultSortAsc={true}
    pagination
    highlightOnHover
    dense
  />
</DataTableExtensions>
)}

     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
